/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import './custom.scss';

function ChipInput(props) {
  const {
    wrapperClass = '',
    name = '',
    value = [],
    onChange,
    placeholder
  } = props;

  const [valueInput, setValueInput] = useState('');
  const [chips, setChips] = useState(value);

  useEffect(() => {
    onChange(chips);
  }, [chips]);

  const handleChipOnAdd = () => {
    let newChips = [...chips];

    newChips = newChips.slice();
    const isFound = newChips.some((item) => item.toLowerCase() === valueInput.toLowerCase());
    if (isFound || valueInput.trim() === '') return;
    newChips.push(valueInput);
    setChips(newChips);
    setValueInput('');
  };

  const handleChipOnRemove = (index) => {
    const newChips = [...chips];

    // Remove this chip in current list
    newChips.splice(index, 1);
    setChips(newChips);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && valueInput.trim() !== '') {
      handleChipOnAdd();
    }
  };

  return (
    <div className={`chip-input__wrapper ${wrapperClass}`}>
      <div className="input-wrapper">
        <input
          name={name}
          onChange={(e) => setValueInput(e.target.value)}
          onKeyDown={handleKeyDown}
          value={valueInput}
          placeholder={placeholder}
        />
        <div className="icon" onClick={handleChipOnAdd} aria-hidden="true">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7H13" stroke="#21111A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M7 1L7 13" stroke="#21111A" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      <div className="chips">
        {chips.map((chip, index) => (
          <div className="chip" key={index}>
            <span>{chip}</span>
            <div className="icon" onClick={handleChipOnRemove} aria-hidden="true">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 1.5L10.5 10.5" stroke="#21111A" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M10.5 1.5L1.5 10.5" stroke="#21111A" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChipInput;
