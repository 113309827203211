/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledPopover,
  PopoverBody
} from 'reactstrap';
import './custom.scss';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { getAPIEndpoint } from '../../helper/helper';
import { load, identify, pageView } from '../../helper/rudderStackService';

const utmMedium = 'content';

class CandidateNurture extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coachAvatar: '',
      coachName: '',
      cmsUrl: '',
      oneClickOnboardUrl: '',
      subTitle: '',
      isShow: true,
      nurtureStepName: '',
      isLoading: true,
      utmSource: '',
      utmCampaign: ''
    };
    this.wrapperRef = React.createRef();
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const fullUrl = window.location.href;
    const token = urlParams.get('token');
    const utmSource = urlParams.get('utm_source');
    const utmMediumFromUrl = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    axios.get(`${getAPIEndpoint(fullUrl)}candidatenurture?token=${token}`).then((res) => {
      const response = res.data;
      if (response.statusCode === 200 && response.data) {
        const { rudderStackData } = response.data;
        load(rudderStackData.writeKey, rudderStackData.dataPlanUrl);
        identify(rudderStackData.identifyData.user_id, {
          ...rudderStackData.identifyData,
          utm_source: utmSource,
          utm_medium: utmMediumFromUrl,
          utm_campaign: utmCampaign
        });
        this.setState((prevState) => ({
          ...prevState,
          coachAvatar: response.data.coachAvatar,
          coachName: response.data.coachName,
          cmsUrl: response.data.cmsUrl,
          oneClickOnboardUrl: response.data.oneClickOnboardUrl,
          subTitle: response.data.subTitle,
          nurtureStepName: response.data.nurtureStepName,
          isLoading: false,
          utmSource,
          utmCampaign
        }), () => {
          this.handleCollapseMobile();
        });
      }
    });
  }

  componentDidUpdate() {
    this.capturePageviewEvent();
  }

  // eslint-disable-next-line class-methods-use-this
  capturePageviewEvent = () => {
    setTimeout(() => {
      const { nurtureStepName } = this.state;
      pageView(nurtureStepName);
    }, 5000);
  };

  // eslint-disable-next-line class-methods-use-this
  collapseBookSession = (isShow) => {
    this.setState((prevState) => ({
      ...prevState,
      isShow
    }));
  };

  // eslint-disable-next-line class-methods-use-this
  redirectToOneClickOnboardFlow = () => {
    const {
      oneClickOnboardUrl, utmSource, utmCampaign
    } = this.state;
    window.location.href = `${oneClickOnboardUrl}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
  };

  // eslint-disable-next-line class-methods-use-this
  handleCollapseMobile = () => {
    if (this.wrapperRef.current) {
      this.wrapperRef.current.toggle();
    }
  };

  render() {
    const {
      coachAvatar,
      coachName,
      cmsUrl,
      subTitle,
      isShow,
      nurtureStepName,
      isLoading
    } = this.state;
    return (
      <>
        <Helmet>
          <title>{nurtureStepName}</title>
        </Helmet>
        <div>
          <iframe title="main-frame" style={{ width: '100%', height: '99vh' }} src={cmsUrl} frameBorder="0" />
        </div>
        {isShow ? !isLoading && (
          <Container className="d-none d-md-block">
            <Row>
              <Col className="mx-auto fixed-bottom mb-4 bg-white px-4 py-4 ja-candidate-nurture-coach-border-radius ja-candidate-nurture-coach-box-shadow" md={10} lg={8} xl={6}>
                <Row>
                  <img onClick={() => this.collapseBookSession(false)} className="position-absolute JA-Custom-Collapse-Image JA-cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAA8ElEQVRoge3YOw7CMBBF0bcJLNj/VuiSihQsBwoyTQTE9vxs6V0pEg0zOgRFYIAxxhhjbMoKgDuAZX8duXe12nsB8ADw2q8ngJt2aPTe47AojOneX8O8MaZ7z4Z5YUz31g6zxpjvXRuGybVB91Qp+4zWvYs1RIPpRZxCWm+x5mum3XX1XlCDcUdEYMIQnphwhAcmDWGJSUdImsfkpnyv+S9uzaeafieyMK6IKEwIwhsTipCsMSkIyQqTipC0mCEQUi9mKITU8y9vOITU8r97WIRUcxIyPEL6dzY1DUL6dlo4HUIq+BwSRJ8bM8YYY2zq3kHIKCH7FRG+AAAAAElFTkSuQmCC" alt="Collapse icon" />
                  <Col md={2} className="my-auto">
                    <img src={coachAvatar} alt="Coach avatar" className="rounded-circle JA-coach-avatar" />
                  </Col>
                  <Col className="px-2">
                    <h5>{coachName}</h5>
                    <span>
                      {subTitle}
                    </span>
                  </Col>
                  <Col md="auto" className="align-self-end">
                    <Button className="ja-candidate-nurture-button-border-radius ja-candidate-nurture-button" onClick={this.redirectToOneClickOnboardFlow}>
                      Book session
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        ) : !isLoading && (
          <Container fluid>
            <Col className="d-none d-md-block position-absolute" style={{ bottom: '1.125rem', right: '1rem' }}>
              <Row className="justify-content-end">
                <Col lg={1} md={2} className="text-right">
                  <img onClick={() => this.collapseBookSession(true)} src={coachAvatar} alt="Coach avatar" className="rounded-circle img-fluid img-coach-mobile JA-cursor-pointer" style={{ width: '60%' }} />
                </Col>
              </Row>
            </Col>
          </Container>
        )}
        {!isLoading && (
          <Col className="d-block d-md-none position-absolute" style={{ bottom: '1.125rem', right: '1rem' }}>
            <Row className="justify-content-end">
              <Col xs={3}>
                <div>
                  <UncontrolledPopover
                    placement="top"
                    target="UncontrolledPopover"
                    className="JA-candidate-nurture-popover-wrapper d-block d-sm-none"
                    ref={this.wrapperRef}
                  >
                    <PopoverBody>
                      <Row>
                        <Col>
                          <h5>Book session?</h5>
                        </Col>
                        <Col xs="auto" className="my-auto">
                          <img onClick={() => this.handleCollapseMobile()} className="JA-Custom-Collapse-Image JA-cursor-pointer" alt="Collapse button" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAAwklEQVRoge3XTQ6CMBCG4dcGjmCj97+QysKl3gQXThNiCGmrpW38nhWLTviGn6GAiIiIiIhUNAIuo85ZbVUj8AQugE+o81bzAIYCuaI5CzIDE3FNeFs7W23O3fupI3DjHegOnBPWnoqnixTTRLPhg60mmg8frDXRTfhg+ZJOH8cpk6qq5VUveuX3HGGHHc/1la4fobUXNuU7UdXWtGm+iZhR2WwTKXO+uSYccKXjzdxA59tpLEDuD0318CIiIiIif+wFrhxORqfJXCsAAAAASUVORK5CYII=" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>{coachName}</h6>
                          <span>
                            {subTitle}
                          </span>
                          <Button className="ja-candidate-nurture-button-border-radius ja-candidate-nurture-button mt-3 w-100" onClick={this.redirectToOneClickOnboardFlow}>
                            Book session
                          </Button>
                        </Col>
                      </Row>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
                <img src={coachAvatar} alt="Coach avatar" className="rounded-circle img-fluid img-coach-mobile" id="UncontrolledPopover" type="button" />
              </Col>
            </Row>
          </Col>
        )}
      </>
    );
  }
}
export default CandidateNurture;
