/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Button, Modal } from 'reactstrap';
import Icon from '../Icon';
import './custom.scss';

const MODAL_INFO_TYPE = 'INFO';
function ModalBase(data) {
  const {
    open, modalType, handleClose, handleChoice, isToS, choiceBtnRef, headerCloseButton, isCenterButton, size, isDisabled, isSubmit, backdrop,
    data: {
      title, content, btnSuccess, btnCancel
    }, style, className
  } = data;

  let toggle = null;
  if (open && modalType !== MODAL_INFO_TYPE) {
    toggle = handleClose;
    if (headerCloseButton) {
      toggle = headerCloseButton;
    }
  }
  return (
    <Modal
      centered
      isOpen={open}
      contentClassName="bg-white"
      className={className}
      toggle={toggle}
      style={style || null}
      size={size || null}
      backdrop={backdrop || null}
    >
      <div className="modal-wrapper">
        <div className="d-flex align-items-center justify-content-between pb-3">
          {(typeof title === 'string' || title instanceof String) ? <p className="mb-0 font-weight-bold font-size-xl">{title}</p> : <div>{title}</div>}
          {(modalType !== MODAL_INFO_TYPE && toggle) && (<Icon className="cursor-pointer" name="faTimes" color="#c2c4c6" size="2x" onClick={toggle} />)}
        </div>
        <div className="mb-4">
          {content}
        </div>
        <div className={`d-flex justify-content-${isCenterButton ? 'center' : 'end'}`}>
          {btnSuccess && (
            <Button ref={choiceBtnRef} onClick={handleChoice} className="py-2 px-3 btn-yes" disabled={isDisabled || isSubmit}>
              {isSubmit ? (<span className="font-weight-bold">Processing...</span>) : (<span className="font-weight-bold">{btnSuccess}</span>)}
            </Button>
          )}
          {(btnCancel && modalType !== MODAL_INFO_TYPE && !isToS) && (
            <Button className="bg-white ml-2 py-2 px-3 btn-no" onClick={handleClose} disabled={isSubmit}>
              <span className="font-weight-bold">{btnCancel}</span>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

function ModalDefault(props) {
  const {
    open, data, isToS, modalType, autoCloseWhenYes = true, style, parentRef, isCenterButton, isDisabledButton = true
  } = props;
  let choiceBtnRef = useRef(null); // avoid double click
  if (parentRef) {
    choiceBtnRef = parentRef;
  }
  // To handle no button
  const handleClose = () => {
    props.handleClose();
  };

  // To handle yes button
  const handleChoice = () => {
    if (autoCloseWhenYes) {
      handleClose();
    }
    if (isDisabledButton && !choiceBtnRef?.current?.isDisabled) { // avoid double click
      if (choiceBtnRef.current) {
        choiceBtnRef.current.isDisabled = true;
      }
      props.handleChoice();
    } else {
      props.handleChoice();
    }
  };

  // To handle close header icon
  const headerCloseButton = () => {
    if (props.headerCloseButton) {
      props.headerCloseButton();
    } else {
      props.handleClose();
    }
  };

  return (
    <ModalBase
      open={open}
      modalType={modalType}
      handleClose={handleClose}
      handleChoice={handleChoice}
      headerCloseButton={headerCloseButton}
      data={data}
      isToS={isToS}
      choiceBtnRef={choiceBtnRef}
      style={style}
      isCenterButton={isCenterButton}
    />
  );
}

export default ModalDefault;
