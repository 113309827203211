import { toast } from 'react-toastify';
// eslint-disable-next-line import/prefer-default-export
export const getAPIEndpoint = (appUrl) => {
  if (appUrl.toString().includes('go-test.jobaccelerator.com')) {
    return 'https://api-test.hellomonday.co/';
  }
  if (appUrl.toString().includes('go-staging.jobaccelerator.com')) {
    return 'https://api-staging.hellomonday.co/';
  }
  if (appUrl.toString().includes('go.jobaccelerator.com')) {
    return 'https://api.hellomonday.co/';
  }
  return 'https://api-dev.hellomonday.co/';
};

// To notify message
export const showNotification = (message, type = 'success', id = null) => toast[type](message, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  toastId: id
});

// validate URL
export const validURL = (str) => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/.test(str);
