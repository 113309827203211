/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './custom.scss';

const assertMaxFileSize = 5000000; // bytes ~ 5MB;
const assertDocument = {
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

function CustomRadioGroup(props) {
  const {
    id, answer, checked, onChange, name, childAnswers
  } = props;

  const { ChildAnswers } = answer;

  const [state, setState] = useState({
    value: {
      name: '',
      answer: '',
      answerValue: childAnswers || {}
    },
  });

  useEffect(() => {
    onChange(state);
    if (childAnswers) {
      //
    }
  }, [state]);

  const customStyles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    container: () => ({
      position: 'relative'
    }),
    control: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      minHeight: '3rem',
      boxShadow: 'none',
      background: isDisabled ? '#FFFFFF' : '',
      borderColor: isFocused ? '#E5E5E5' : '#E5E5E5',
      '&:hover': {
        borderColor: styles.isFocused ? '#E5E5E5' : '#E5E5E5'
      }
    }),
    input: (styles) => ({
      ...styles,
      '& input': {
        backgroundColor: 'transparent !important',
        padding: '0 !important'
      }
    }),
    menu: (styles) => ({
      ...styles,
      width: 'auto',
      minWidth: '100%',
      marginTop: '0.25rem',
      zIndex: 999,
      '& .Button-AddNewClient': {
        width: '100%',
        padding: '1rem'
      }
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#FFFFFF',
      borderRadius: '0px 0px 4px 4px !important',
      borderBottom: '1px solid #E5E5E5',
      padding: '0.5rem'
    }),
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      display: 'flex',
      justifyContent: 'space-between',
      padding: data.value === '' ? '1.25rem' : '0.75rem',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      color: '#1E2C42',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: isFocused || isSelected ? '#FAFAFA' : 'white',
      '&:hover, & label:hover': {
        cursor: 'pointer'
      },
      '&:active': {
        backgroundColor: '#E5E5E5'
      },
      '& span.MuiCheckbox-root': {
        padding: 0,
        marginLeft: 'auto'
      },
      '& label': {
        marginBottom: 0,
        paddingBottom: 0
      }
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '0.25rem 0.25rem 0.25rem 1rem'
    }),
    placeholder: (styles) => ({
      ...styles,
      whiteSpace: 'nowrap',
    }),
    groupHeading: (styles) => ({
      ...styles,
      marginBottom: '0.5em',
      '& + div': {
        paddingLeft: '1rem'
      }
    })
  };

  const handleGetFile = (acceptedFiles, fieldName) => {
    const answerDefault = answer?.IsDefault ? answer.Value : '';
    setState({
      ...state,
      value: {
        ...state.value,
        name: fieldName,
        answer: state.answer || answerDefault,
        answerValue: {
          [fieldName]: acceptedFiles
        }
      }
    });
  };

  const handleGetRejectedFile = (rejectedFiles) => {
    const { code } = rejectedFiles[0].errors[0];
    if (code === 'file-invalid-type') {
      const message = 'Unsupported file. Only accept .doc, .pdf, .docx, .jpg, .jpeg, .png.';
      toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    if (code === 'file-too-large') {
      const message = 'Unsupported file. Maximum upload file size is 5 MB.';
      toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const handleChangeSelect = (value, action) => {
    const answerDefault = answer?.IsDefault ? answer.Value : '';
    setState({
      ...state,
      value: {
        ...state.value,
        answer: state.answer || answerDefault,
        name: action.name,
        answerValue: {
          ...state.value.answerValue,
          [action.name]: value
        }
      }
    });
  };

  const handleChangeInput = (e) => {
    const answerDefault = answer?.IsDefault ? answer.Value : '';
    setState({
      ...state,
      value: {
        ...state.value,
        answer: state.answer || answerDefault,
        name: e.target.name,
        answerValue: {
          ...state.value.answerValue,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      value: {
        ...state.value,
        answer: e.target.value,
        answerValue: {},
      },
    });
  };

  const renderChildren = (childAnswer) => {
    if (childAnswer.InputType === 'Select') {
      const options = childAnswer.Options.map((option) => ({ label: option, value: option }));
      return (
        <Select
          className="mb-2"
          options={options}
          styles={customStyles}
          getOptionValue={(option) => `${option.value}`}
          getOptionLabel={(option) => `${option.label}`}
          placeholder={childAnswer.Placeholder}
          onChange={handleChangeSelect}
          name={childAnswer.Name}
          value={state.value.answerValue[childAnswer.Name] || ''}
        />
      );
    }
    if (childAnswer.InputType === 'File') {
      const { answerValue } = state.value;
      return (
        <Dropzone
          onDropAccepted={(rejectedFiles) => handleGetFile(rejectedFiles, childAnswer.Name)}
          onDropRejected={handleGetRejectedFile}
          multiple={false}
          noKeyboard
          accept={assertDocument}
          maxSize={assertMaxFileSize}
          disabled={!checked}
          name={childAnswer.Name}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="d-flex justify-content-center align-items-center mx-auto mt-4 rounded-2 drop-box rounded"
              style={{ height: '5.75rem' }}
            >
              <input {...getInputProps()} />
              <p className="mt-3 px-3">
                {(!answerValue[childAnswer.Name] || !answerValue[childAnswer.Name][0])
                  && (
                    <small>
                      Drag and drop or
                      <span> browse files</span>
                    </small>
                  )}
                {answerValue[childAnswer.Name] && answerValue[childAnswer.Name][0] && (
                  <small style={{ color: '#01A4A4', lineBreak: 'anywhere' }}><span>{answerValue[childAnswer.Name][0].name}</span></small>
                )}
              </p>
            </div>
          )}
        </Dropzone>
      );
    }
    return (
      <Input
        className="mb-2 px-3"
        style={{ height: '3rem' }}
        placeholder={childAnswer.Placeholder}
        onChange={handleChangeInput}
        name={childAnswer.Name}
        value={state.value.answerValue[childAnswer.Name] || ''}
      />
    );
  };

  return (
    <>
      <FormGroup className={`border rounded p-3 px-4 form-group ${checked ? 'active' : ''}`}>
        <div className="custom-control custom-radio">
          <Input
            name={name}
            type="radio"
            id={id}
            value={answer.Value}
            checked={checked}
            onChange={handleChange}
            className="custom-control-input"
          />
          {' '}
          <Label className="mb-0 ms-2 custom-control-label" htmlFor={id}>
            {answer.Value}
          </Label>
        </div>
        {checked && ChildAnswers && ChildAnswers.map((childAnswer, index) => (
          <div key={`${index + childAnswer.Name}`} className="mt-3">
            {renderChildren(childAnswer)}
          </div>
        ))}
        {checked && answer?.Description && (<div className="pl-4 JA-Answer-Description"><span className="text-secondary"><small>{answer.Description}</small></span></div>)}
      </FormGroup>
      <ToastContainer />
    </>
  );
}

export default CustomRadioGroup;
