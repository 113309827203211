/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Background from '../../../assets/images/bg_feedback.png';
import LogoCandidate from '../../../assets/images/LogoCandidate.svg';
import './custom.scss';

function HeaderFeedback(props) {
  const { text, isSubmitted } = props;
  return (
    <Container fluid className="header-feedback">
      <img src={Background} alt="background" className="header-feedback-image" />
      <img src={LogoCandidate} alt="logo" className="ml-4 mt-4 header-feedback-logo" />
      <Container className="header-feedback-text">
        <Row className="h-100">
          <Col md={6} className="d-flex align-items-center">
            <h1 className={isSubmitted ? 'isSubmitted' : ''}>{text}</h1>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default HeaderFeedback;
