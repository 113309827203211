/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import Select, { components } from 'react-select';

import './custom.scss';

function FloatingSelect(props) {
  const {
    options, label, onChange, value, name
  } = props;

  const elSpan = useRef(null);

  function CustomInput({ children, ...rest }) {
    return (
      <components.Control {...rest}>
        <span ref={elSpan} className={value ? 'select-float active' : 'select-float'}>
          {label}
        </span>
        {children}
      </components.Control>
    );
  }

  const handleChange = (valueSelect) => {
    onChange(valueSelect, name);
  };

  const customSelectStyles = {
    control: (baseStyles, state) => {
      if (elSpan.current) {
        if (state.isFocused || state.hasValue) {
          elSpan.current.classList.add('active');
        } else {
          elSpan.current.classList.remove('active');
        }
      }
      return {
        ...baseStyles,
        height: '3rem',
        paddingLeft: '1.5rem',
        borderColor: state.isFocused || state.hasValue ? '#42B4B5' : '#DDDAD6',
        '&:hover': {
          borderColor: '#42B4B5'
        },
        boxShadow: 'none',
        position: 'relative',
      };
    },
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: '0'
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: '#73706C'
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: 'none'
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 99
    }),
    option: (baseStyles, { isFocused, isSelected }) => ({
      ...baseStyles,
      fontSize: '0.875rem',
      color: '#1E2C42',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: isFocused || isSelected ? '#FAFAFA' : 'white',
      '&:hover, & label:hover': {
        cursor: 'pointer'
      },
      '&:active': {
        backgroundColor: '#E5E5E5'
      },
      '& span.MuiCheckbox-root': {
        padding: 0,
        marginLeft: 'auto'
      },
      '& label': {
        marginBottom: 0,
        paddingBottom: 0
      }
    }),
  };

  return (
    <Select
      options={options}
      styles={customSelectStyles}
      components={{ Control: CustomInput }}
      onChange={handleChange}
      value={value}
      placeholder={null}
    />
  );
}

export default FloatingSelect;
