import React from 'react';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';

import './App.css';
import CandidateNurture from './components/CandidateNurture';
import Quiz from './components/Quiz';
import ErrorPage from './components/ErrorPage';
import OptOut from './components/OptOut';
import TermsOfService from './components/TermOfService';
import Feedback from './components/Feedback';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/CandidateNurture" element={<CandidateNurture />} />
          <Route path="/OptOut" element={<OptOut />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/Quiz" element={<Quiz />} />
          <Route path="/Feedback" element={<Feedback />} />
          <Route path="/ErrorPage" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
