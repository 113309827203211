// Candidate final feedback
export const PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_KEY = 'view_candidate_final_feedback';
export const PAGE_VIEW_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY = 'view_resend_candidate_final_feedback';
export const PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK = 'Final Feedback';
export const PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_RESUBMIT = 'Final Feedback Resubmission';

export const SUBMIT_CANDIDATE_FINAL_FEEDBACK_KEY = 'submit_candidate_final_feedback';
export const SUBMIT_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY = 'submit_resend_candidate_final_feedback';
export const SUBMIT_CANDIDATE_FINAL_FEEDBACK = 'candidate_completed_final_feedback';
export const SUBMIT_CANDIDATE_FINAL_FEEDBACK_RESUBMIT = 'candidate_resubmit_final_feedback';

export const CANCEL_CANDIDATE_FINAL_FEEDBACK_KEY = 'cancel_candidate_final_feedback';
export const CANCEL_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY = 'cancel_resend_candidate_final_feedback';
export const CANCEL_CANDIDATE_FINAL_FEEDBACK = 'candidate_cancel_final_feedback';
export const CANCEL_CANDIDATE_FINAL_FEEDBACK_RESUBMIT = 'candidate_cancel_final_feedback_resubmission';

// Candidate first session feedback
export const PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'view_candidate_first_session_feedback';
export const PAGE_VIEW_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'view_resend_candidate_first_session_feedback';
export const PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK = 'First Session Feedback';
export const PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT = 'First Session Feedback Resubmission';

export const SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'submit_candidate_first_session_feedback';
export const SUBMIT_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'submit_resend_candidate_first_session_feedback';
export const SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK = 'candidate_completed_first_session_feedback';
export const SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT = 'candidate_resubmit_first_session_feedback';

export const CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'cancel_candidate_first_session_feedback';
export const CANCEL_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY = 'cancel_resend_candidate_first_session_feedback';
export const CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK = 'candidate_cancel_first_session_feedback';
export const CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT = 'candidate_cancel_first_session_feedback_resubmision';

// Candidate workshop feedback
export const PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'view_candidate_workshop_feedback';
export const PAGE_VIEW_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'view_resend_candidate_workshop_feedback';
export const PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK = 'Workshop Feedback';
export const PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT = 'Workshop Feedback Resubmission';

export const SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'submit_candidate_workshop_feedback';
export const SUBMIT_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'submit_resend_candidate_workshop_feedback';
export const SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK = 'candidate_completed_workshop_feedback';
export const SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT = 'candidate_resubmit_workshop_feedback';

export const CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'cancel_candidate_workshop_feedback';
export const CANCEL_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY = 'cancel_resend_candidate_workshop_feedback';
export const CANCEL_CANDIDATE_WORKSHOP_FEEDBACK = 'candidate_cancel_workshop_feedback';
export const CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT = 'candidate_cancel_workshop_feedback_resubmission';

export const CANDIDATE_ALREADY_SUBMITTED_WORKSHOP_FEEDBACK = 'Candidate already submitted workshop feedback';
export const CANDIDATE_ALREADY_SUBMITTED_FIRST_SESSION_FEEDBACK = 'Candidate already submitted first session feedback';
