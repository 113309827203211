/* eslint-disable react/prop-types */
import React from 'react';
import './custom.scss';

export default function RatingNumber(props) {
  const {
    readOnly,
    name,
    initialRating,
    onChange,
    stringLowRate,
    stringHighRate,
    rateRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    textWrapperClass = '',
  } = props;

  return (
    <>
      <div className="d-flex rating-number-wrapper">
        {
          rateRange.map((item) => (
            <div
              key={item}
              className={`rating-item ${readOnly ? 'pointer-events-none' : ''} ${initialRating === item ? 'active' : ''}`}
              onClick={() => onChange(item, name)}
              aria-hidden="true"
            >
              {item}
            </div>
          ))
        }
      </div>
      <div className={`mt-3 d-flex justify-content-between align-items-center rate-description ${textWrapperClass}`}>
        <div className="string-low">{stringLowRate}</div>
        <div className="horizontal-line" />
        <div className="string-high">{stringHighRate}</div>
      </div>
    </>
  );
}
