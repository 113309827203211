/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Input
} from 'reactstrap';
import './custom.scss';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { orderBy } from 'lodash';
import CustomRadioGroup from '../Common/CustomRadioGroup';
import { getAPIEndpoint } from '../../helper/helper';
import QuizComplete from './QuizComplete';
import SetPassword from './SetPassword';
import ChipInput from '../Common/ChipInput';
import ErrorPage from '../ErrorPage';
import { identify, load, track } from '../../helper/rudderStackService';
import Loading from '../Common/Loading';

class Quiz extends PureComponent {
  constructor(props) {
    super(props);
    this.passwordPage = React.createRef();
    this.state = {
      choose: '',
      quizData: [],
      question: null,
      histories: [],
      buttonLoading: false,
      isFirstQuestion: true,
      candidateData: {},
      progressBar: 4,
      answers: [],
      childAnswers: {},
      isDisabledNextStep: false,
      isLoading: true,
      redirectURL: '',
      isError: false,
      errorMessage: '',
      textAnswer: '',
      isSetPasswordPage: false,
      isSetPasswordSuccessful: false,
      isFinalPage: false,
      isDropout: false,
      resetToken: ''
    };
  }

  componentDidMount() {
    const { state } = this;
    const { quizData } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const fullUrl = window.location.href;
    const token = urlParams.get('token');
    const utmSource = urlParams.get('utm_source');
    const utmMediumFromUrl = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    window.addEventListener('beforeunload', this.onUnload);
    const decodeToken = this.decodeJwt(token);
    if (decodeToken?.ticket) {
      this.setState({
        ...this.state,
        resetToken: decodeToken?.ticket
      });
    }
    if (!quizData || quizData.length === 0) {
      axios.get(`${getAPIEndpoint(fullUrl)}quiz?token=${token}`)
        .then((res) => {
          const response = res.data;
          if (response.statusCode === 200) {
            // rudderstack implement
            const { rudderStackData } = response.data;
            load(rudderStackData.writeKey, rudderStackData.dataPlanUrl);
            identify(rudderStackData.identifyData.user_id, {
              ...rudderStackData.identifyData,
              utm_source: utmSource,
              utm_medium: utmMediumFromUrl,
              utm_campaign: utmCampaign
            });

            // quiz implement
            const quizDataTemp = orderBy(response.data?.quizData, ['Order'], ['asc']);
            this.setState({
              state,
              quizData: quizDataTemp,
              question: quizDataTemp[0],
              candidateData: response.data?.candidateData,
              isLoading: false,
              redirectURL: response?.data?.redirectURL,
            }, () => {
              this.renderQuestion();
              this.handleUpdateLocalStorage();
            });
          } else if (response.statusCode === 201) {
            // Redirect to portal
            window.location.href = response.data;
          } else {
            this.setState({
              ...state,
              isError: true,
              errorMessage: response.message
            });
          }
        });
    } else {
      this.renderQuestion();
      this.handleUpdateLocalStorage();
    }
  }

  onUnload = (e) => {
    const { isDropout } = this.state;
    if (!isDropout) {
      e.preventDefault();
      track('candidate_drop_out_onboard_quiz', {
        quizHistories: localStorage.getItem('quizHistory'),
      });
    }
    return true;
  };

  renderQuestion = (selected = null) => {
    const { quizData, question } = this.state;
    let qData = quizData.find((x) => x.id === question.id);
    qData = qData || quizData[0];
    if (qData?.InputType === 'RadioGroup') {
      return this.renderRadioGroup(qData.Answers, selected);
    }
    if (qData?.InputType === 'TextBoxMultiple') {
      return this.renderTextBoxMultiple(qData, selected);
    }
    if (qData?.InputType === 'TextBox') {
      return this.renderTextBox(qData, selected);
    }
    return '';
  };

  renderTextBox = (questionData, selected) => {
    const { state } = this;
    this.setState({
      ...state,
      textAnswer: selected || '',
    }, () => { this.validation(); });
  };

  renderTextBoxMultiple = (questionData, selected) => {
    const { state } = this;
    const answers = selected ? selected?.split('|') : [];
    this.setState({
      ...state,
      answers,
    }, () => { this.validation(); });
  };

  renderRadioGroup = (arr, selected) => {
    const { state } = this;
    let choose;
    arr.map((item) => {
      if (item.IsDefault) {
        choose = item.Value;
      }
      return true;
    });
    this.setState({
      ...state,
      choose: selected || choose,
    }, () => { this.validation(); });
  };

  handleGoBack = () => {
    const { state } = this;
    const { quizData, progressBar } = this.state;
    const quizHistories = JSON.parse(localStorage.getItem('quizHistory'));
    const latestQuestionID = quizHistories[quizHistories.length - 1].QuestionID;
    const latestQuestion = quizData.find((x) => x.id === latestQuestionID);
    this.setState({
      ...state,
      question: latestQuestion,
      progressBar: progressBar - 1,
      isFirstQuestion: quizHistories?.length === 1 || false,
    }, async () => {
      this.renderQuestion(quizHistories[quizHistories.length - 1].Answer);
      this.handleUpdateLocalStorage('back');
    });
  };

  handleRedirectToPortal = () => {
    const { state } = this;
    const { redirectURL } = this.state;
    this.setState({
      ...state,
      isDropout: true
    }, () => { window.location.href = redirectURL; });
  };

  handleNext = async () => {
    const { state } = this;
    const {
      choose, question, quizData, progressBar, candidateData, resetToken
    } = this.state;
    // handle save to local storage then save to DB
    this.handleUpdateLocalStorage('next', true);

    // Render next question
    let answerData;
    if (question.InputType === 'RadioGroup') {
      answerData = question?.Answers?.find((x) => x.Value === choose);
    } else if (question.InputType === 'TextBoxMultiple') {
      answerData = question?.Answers[0];
    } else if (question.InputType === 'TextBox') {
      answerData = question?.Answers[0];
    }
    const nextQuestion = quizData.find((x) => x.id === answerData?.NextQuestion);

    this.setState({
      ...state,
      question: nextQuestion,
      buttonLoading: true,
      isFirstQuestion: false,
      progressBar: progressBar + 1,
      answers: [],
      isSetPasswordPage: (candidateData.isSetPassword || candidateData.IsLoggedIn)
        ? false : answerData.IsFinalPage,
      isFinalPage: answerData.IsFinalPage
    }, async () => {
      const { stateIn } = this;
      if (!candidateData.isSetPassword && !candidateData.IsLoggedIn && answerData.IsFinalPage && resetToken) {
        await this.delay(1000);
        this.handleRedirectToSetPasswordPage();
      } else if ((candidateData.isSetPassword || candidateData.IsLoggedIn)
      && answerData.IsFinalPage) {
        await this.delay(1000);
        this.handleRedirectToPortal();
      } else {
        this.renderQuestion();
        await this.delay(1000);
        this.validation();
        this.setState({
          ...stateIn,
          buttonLoading: false,
        });
      }
    });
  };

  getBinaryFromFile = (file) => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', (err) => reject(err));

      reader.readAsDataURL(file);
    })
  );

  handleSave = async (key, data, result, isFile, keyName) => {
    const { childAnswers } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const fullUrl = window.location.href;
    const token = urlParams.get('token');

    // Prepare history data
    const payload = {
      key,
      token,
      data,
      filename: isFile ? childAnswers[keyName][0].name : null,
    };

    axios.post(`${getAPIEndpoint(fullUrl)}quiz`, JSON.stringify(payload))
      .then(async (res) => {
        const response = res.data;
        if (response.statusCode === 200) {
          if (result) {
            this.handleSave('RESULT', result);
          }
          if (key === 'RESULT') {
            // put rudder_stack event
            track('candidate_completed_onboard_quiz', {
              result: data
            });
          }
          if (isFile) {
            const binaryFile = await this.getBinaryFromFile(childAnswers[keyName][0]);
            axios.post(`${getAPIEndpoint(fullUrl)}upload`, binaryFile.split(',')[1], {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: response?.data?.uploadToken
              }
            }).then((res1) => {
              const response1 = res1.data;
              if (response1.statusCode !== 200) {
                console.error('error', res1);
              }
            });
          }
        } else {
          console.error('error', res);
        }
      });
  };

  validation = () => {
    const { state } = this;
    const {
      question, choose, answers, childAnswers, textAnswer
    } = this.state;
    let isValidationFailed = false;
    if (question?.InputType === 'RadioGroup') {
      const selectedAnswer = question?.Answers?.find((x) => x.Value === choose);
      if (selectedAnswer?.ChildAnswers) {
        for (let i = 0; i < selectedAnswer?.ChildAnswers?.length; i += 1) {
          if (!childAnswers[selectedAnswer?.ChildAnswers[i]?.Name]) {
            isValidationFailed = true;
            break;
          }
        }
      } else {
        isValidationFailed = false;
      }
    } else if (question?.InputType === 'TextBoxMultiple') {
      isValidationFailed = (!answers || answers.length === 0) || false;
    } else if (question?.InputType === 'TextBox') {
      isValidationFailed = textAnswer === '' || false;
    }
    this.setState({
      ...state,
      isDisabledNextStep: isValidationFailed
    });
  };

  handleUpdateLocalStorage = (key, isSyncDB) => {
    const {
      choose, question, answers, childAnswers, textAnswer
    } = this.state;
    let newHistories = [];
    const histories = JSON.parse(localStorage.getItem('quizHistory'));
    let isFile = false;
    let keyName = '';
    if (key === 'next') {
      if (question.InputType === 'RadioGroup') {
        const selectedAnswer = question?.Answers.find((x) => x.Value === choose);
        if (selectedAnswer?.ChildAnswers) {
          const params = {};
          selectedAnswer.ChildAnswers.map((answer) => {
            if (answer.InputType === 'File') {
              params[answer.Name] = childAnswers[answer.Name][0]?.name;
              isFile = true;
              keyName = answer.Name;
            } else if (answer.InputType === 'Select') {
              params[answer.Name] = childAnswers[answer.Name]?.value;
            } else {
              params[answer.Name] = childAnswers[answer.Name];
            }
            return true;
          });
          newHistories = histories.concat([{
            QuestionID: question.id, Answer: choose, AnswerValue: JSON.stringify(params)
          }]);
        } else {
          newHistories = histories.concat([{ QuestionID: question.id, Answer: choose }]);
        }
      } else if (question.InputType === 'TextBoxMultiple') {
        newHistories = histories.concat([{ QuestionID: question.id, Answer: answers.join('|') }]);
      } else if (question.InputType === 'TextBox') {
        newHistories = histories.concat([{ QuestionID: question.id, Answer: textAnswer }]);
      }
    } else if (key === 'back') {
      const latestQuestionID = histories[histories.length - 1].QuestionID;
      newHistories = histories.filter((x) => x.QuestionID !== latestQuestionID);
    }
    localStorage.setItem('quizHistory', JSON.stringify(newHistories));

    // handle save to DB
    if (isSyncDB) {
      let result;
      if (question.InputType === 'RadioGroup') {
        const answer = question?.Answers?.find((x) => x.Value === choose);
        result = answer?.Result;
      }
      this.handleSave('HISTORY', newHistories, result, isFile, keyName);
    }
  };

  delay = (delayInms) => (new Promise((resolve) => setTimeout(resolve, delayInms)));

  handleChangeCheckbox = (e) => {
    const { state } = this;
    const { childAnswers } = this.state;
    const data = e?.value;
    if (data?.name) {
      childAnswers[data?.name] = data?.answerValue[data?.name];
      this.setState({
        ...state,
        choose: data?.answer,
        childAnswers,
      }, () => { this.validation(); });
    } else {
      this.setState({
        ...state,
        choose: data?.answer,
        childAnswers: {},
      }, () => { this.validation(); });
    }
  };

  handleChangeTextBoxMultiple = (e) => {
    const { state } = this;
    this.setState({
      ...state,
      answers: e
    }, () => { this.validation(); });
  };

  handleChangeTextBox = (e) => {
    const value = e?.target?.value;
    const { state } = this;
    this.setState({
      ...state,
      textAnswer: value
    }, () => { this.validation(); });
  };

  decodeJwt = (inputToken) => (JSON.parse(Buffer.from(inputToken.split('.')[1], 'base64').toString()));

  handleRedirectToSetPasswordPage = () => {
    const { resetToken } = this.state;
    const { state } = this;
    if (resetToken) {
      this.setState({
        ...state,
        isDropout: true
      }, () => {
        // eslint-disable-next-line global-require
        window.Buffer = window.Buffer || require('buffer').Buffer;
        const { redirectURL } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const utmSource = urlParams.get('utm_source');
        const utmMediumFromUrl = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
        const decodeToken = this.decodeJwt(token);
        window.location.href = `${redirectURL}/reset?ticket=${decodeToken?.ticket}&utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=${utmMediumFromUrl}&token=${token}`;
      })
    } else {
      const { state } = this;
      this.setState({
        ...state,
        question: null,
        isSetPasswordPage: true
      });
    }
  };

  // save password
  handleSavePassword = async () => {
    if (this.passwordPage.current) {
      this.setState((prevState) => ({
        ...prevState,
        buttonLoading: true
      }));
      const response = await this.passwordPage.current.handleSetPassword();
      if (response.status === 200) {
        track('click_create_password');
        this.setState((prevState) => ({
          ...prevState,
          isSetPasswordSuccessful: true
        }));
      }
      this.setState((prevState) => ({
        ...prevState,
        buttonLoading: false,
      }));
    }
  };

  render() {
    const {
      choose, question, buttonLoading, isFirstQuestion, candidateData, progressBar, answers,
      isDisabledNextStep, isLoading, isError, errorMessage, childAnswers, textAnswer,
      isSetPasswordPage, isSetPasswordSuccessful, isFinalPage, resetToken
    } = this.state;
    if (isError) {
      return (<ErrorPage message={errorMessage} />);
    }
    return (
      <>
        <Helmet>
          <title>Onboard quiz</title>
        </Helmet>
        <Container fluid className={`fixed-top ${isLoading ? 'd-none' : 'd-block'}`}>
          <Row>
            <Col xs={question?.IsFinalPage || isFinalPage || isSetPasswordPage ? 12 : progressBar} style={{ height: '.3rem', background: '#01A4A4' }}>&nbsp;</Col>
            <Col className="bg-white" style={{ height: '.3rem' }}>&nbsp;</Col>
          </Row>
        </Container>
        <Container className={isLoading ? 'd-none' : 'd-block'}>
          <Row>
            <Col lg={12} className="text-center mt-5">
              <svg width="200" height="27" viewBox="0 0 200 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M42.5952 23.908L44.1378 26.9623C45.3155 26.9623 46.3107 26.7735 47.1234 26.3958C47.9362 26.0181 48.5499 25.4352 48.9646 24.6469C49.3959 23.8752 49.6115 22.8981 49.6115 21.7158V8.71031H45.6057V20.7798C45.6057 21.9621 45.3818 22.7749 44.934 23.2183C44.5027 23.6781 43.7231 23.908 42.5952 23.908Z"
                  fill="#21111A"
                />
                <path
                  d="M45.9043 5.60674C46.319 5.95158 46.9078 6.124 47.6708 6.124C48.4006 6.124 48.9812 5.93516 49.4124 5.55747C49.8437 5.17979 50.0593 4.68716 50.0593 4.07958C50.0593 3.42274 49.8437 2.92189 49.4124 2.57705C48.9978 2.21579 48.4172 2.03516 47.6708 2.03516C46.9244 2.03516 46.3439 2.224 45.9292 2.60168C45.5145 2.97937 45.3072 3.472 45.3072 4.07958C45.3072 4.73642 45.5062 5.24547 45.9043 5.60674Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M54.4976 21.1739C55.5757 21.7486 56.7949 22.036 58.155 22.036C59.5317 22.036 60.7508 21.7486 61.8124 21.1739C62.8905 20.5827 63.7365 19.7781 64.3502 18.76C64.9639 17.7255 65.2708 16.5596 65.2708 15.2623C65.2708 13.9322 64.9639 12.7581 64.3502 11.74C63.7365 10.7055 62.8905 9.90084 61.8124 9.3261C60.7508 8.73495 59.5317 8.43937 58.155 8.43937C56.7949 8.43937 55.5757 8.73495 54.4976 9.3261C53.4194 9.90084 52.5735 10.7055 51.9598 11.74C51.346 12.7581 51.0392 13.9322 51.0392 15.2623C51.0392 16.5596 51.346 17.7255 51.9598 18.76C52.5735 19.7781 53.4194 20.5827 54.4976 21.1739ZM59.7224 18.1935C59.2746 18.4891 58.7521 18.6368 58.155 18.6368C57.5744 18.6368 57.0519 18.4891 56.5875 18.1935C56.1231 17.8979 55.7582 17.4956 55.4928 16.9865C55.2274 16.4775 55.0947 15.9027 55.0947 15.2623C55.0947 14.5891 55.2274 13.9979 55.4928 13.4888C55.7582 12.9798 56.1231 12.5775 56.5875 12.2819C57.0519 11.9863 57.5744 11.8385 58.155 11.8385C58.7521 11.8385 59.2746 11.9863 59.7224 12.2819C60.1869 12.5611 60.5518 12.9634 60.8172 13.4888C61.0826 13.9979 61.207 14.5891 61.1904 15.2623C61.207 15.9027 61.0826 16.4775 60.8172 16.9865C60.5518 17.4956 60.1869 17.8979 59.7224 18.1935Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M74.5673 22.036C73.9702 22.036 73.3979 21.9539 72.8505 21.7897C72.3198 21.6255 71.8304 21.412 71.3826 21.1493C71.0294 20.9291 70.7226 20.6834 70.4623 20.4122L70.3625 21.7404H66.6304V3.56231H70.6611V10.0461C70.8917 9.801 71.1571 9.57743 71.4572 9.37537C71.8885 9.07979 72.3612 8.84989 72.8754 8.68568C73.3896 8.52147 73.9204 8.43937 74.4678 8.43937C75.6123 8.43937 76.6241 8.73495 77.5032 9.3261C78.3989 9.91726 79.1038 10.7301 79.618 11.7646C80.1322 12.7827 80.3893 13.9486 80.3893 15.2623C80.3893 16.576 80.1322 17.7419 79.618 18.76C79.1204 19.7781 78.432 20.5827 77.5529 21.1739C76.6738 21.7486 75.6786 22.036 74.5673 22.036ZM73.5223 18.7107C74.1194 18.7107 74.6336 18.5712 75.0649 18.292C75.5127 17.9964 75.8611 17.5941 76.1099 17.0851C76.3587 16.5596 76.4831 15.952 76.4831 15.2623C76.4831 14.5562 76.3587 13.9486 76.1099 13.4396C75.8777 12.9141 75.5376 12.5118 75.0898 12.2326C74.6419 11.9371 74.1194 11.7893 73.5223 11.7893C72.9252 11.7893 72.4027 11.9371 71.9548 12.2326C71.507 12.5118 71.1587 12.9141 70.9099 13.4396C70.6611 13.9486 70.5367 14.5562 70.5367 15.2623C70.5367 15.952 70.6611 16.5596 70.9099 17.0851C71.1587 17.5941 71.507 17.9964 71.9548 18.292C72.4027 18.5712 72.9252 18.7107 73.5223 18.7107Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M89.824 20.0529V21.7897H91.7896V12.8238C91.7896 11.9371 91.5905 11.1817 91.1924 10.5577C90.7943 9.91726 90.2304 9.43284 89.5006 9.10442C88.7707 8.776 87.9082 8.61179 86.913 8.61179C85.868 8.61179 84.8977 8.81705 84.002 9.22758C83.1063 9.6381 82.2853 10.2621 81.5388 11.0996L82.957 12.5036C83.4546 11.8632 84.0186 11.3869 84.6489 11.0749C85.2792 10.7629 85.9841 10.6069 86.7637 10.6069C87.6594 10.6069 88.3892 10.8533 88.9532 11.3459C89.5337 11.8221 89.824 12.4543 89.824 13.2425V14.08H86.8881C85.9427 14.08 85.105 14.1703 84.3752 14.3509C83.662 14.5152 83.0648 14.7697 82.5838 15.1145C82.1194 15.4429 81.7628 15.8617 81.514 16.3707C81.2652 16.8798 81.1408 17.4627 81.1408 18.1196C81.1408 18.9078 81.3315 19.5975 81.713 20.1886C82.1111 20.7634 82.6419 21.2149 83.3054 21.5434C83.9688 21.8718 84.7235 22.036 85.5695 22.036C86.2661 22.036 86.9296 21.9046 87.5599 21.6419C88.1902 21.3792 88.7707 21.0179 89.3015 20.5581C89.4857 20.3986 89.6598 20.2302 89.824 20.0529ZM89.824 18.0354V15.8288H86.5896C86.0422 15.8288 85.5612 15.8781 85.1465 15.9766C84.7318 16.0587 84.3752 16.2065 84.0766 16.42C83.7781 16.6171 83.5542 16.8552 83.4049 17.1343C83.2722 17.3971 83.2058 17.7173 83.2058 18.0949C83.2058 18.5055 83.3136 18.8749 83.5293 19.2034C83.7449 19.5154 84.0352 19.7617 84.4001 19.9423C84.765 20.1065 85.188 20.1886 85.669 20.1886C86.3159 20.1886 86.9213 20.0737 87.4853 19.8438C88.0658 19.6139 88.58 19.3101 89.0278 18.9324C89.3366 18.6607 89.602 18.3617 89.824 18.0354Z"
                  fill="#21111A"
                />
                <path
                  d="M99.4566 22.036C98.2292 22.036 97.1344 21.7404 96.1724 21.1493C95.2104 20.5581 94.4474 19.7617 93.8834 18.76C93.336 17.7419 93.0623 16.5924 93.0623 15.3116C93.0623 14.0472 93.336 12.9141 93.8834 11.9124C94.4474 10.8943 95.2104 10.0897 96.1724 9.49853C97.1344 8.90737 98.2292 8.61179 99.4566 8.61179C100.601 8.61179 101.638 8.84168 102.567 9.30147C103.496 9.74484 104.217 10.3524 104.731 11.1242L103.512 12.6021C103.23 12.2244 102.874 11.8796 102.442 11.5676C102.028 11.2556 101.58 11.0175 101.099 10.8533C100.618 10.6726 100.137 10.5823 99.6556 10.5823C98.7931 10.5823 98.0218 10.7876 97.3418 11.1981C96.6617 11.6086 96.1226 12.1752 95.7246 12.8977C95.3431 13.6038 95.144 14.4084 95.1274 15.3116C95.144 16.2147 95.3513 17.0276 95.7494 17.7501C96.1641 18.4726 96.7115 19.0474 97.3915 19.4743C98.0716 19.8848 98.8346 20.0901 99.6805 20.0901C100.162 20.0901 100.626 20.0162 101.074 19.8684C101.522 19.7042 101.953 19.4743 102.368 19.1787C102.782 18.8832 103.164 18.5219 103.512 18.0949L104.731 19.5728C104.184 20.2954 103.429 20.8865 102.467 21.3463C101.522 21.8061 100.518 22.036 99.4566 22.036Z"
                  fill="#21111A"
                />
                <path
                  d="M108.195 21.1493C109.157 21.7404 110.251 22.036 111.479 22.036C112.54 22.036 113.544 21.8061 114.489 21.3463C115.451 20.8865 116.206 20.2954 116.754 19.5728L115.534 18.0949C115.186 18.5219 114.805 18.8832 114.39 19.1787C113.975 19.4743 113.544 19.7042 113.096 19.8684C112.648 20.0162 112.184 20.0901 111.703 20.0901C110.857 20.0901 110.094 19.8848 109.414 19.4743C108.734 19.0474 108.186 18.4726 107.772 17.7501C107.374 17.0276 107.166 16.2147 107.15 15.3116C107.166 14.4084 107.365 13.6038 107.747 12.8977C108.145 12.1752 108.684 11.6086 109.364 11.1981C110.044 10.7876 110.815 10.5823 111.678 10.5823C112.159 10.5823 112.64 10.6726 113.121 10.8533C113.602 11.0175 114.05 11.2556 114.465 11.5676C114.896 11.8796 115.252 12.2244 115.534 12.6021L116.754 11.1242C116.239 10.3524 115.518 9.74484 114.589 9.30147C113.66 8.84168 112.623 8.61179 111.479 8.61179C110.251 8.61179 109.157 8.90737 108.195 9.49853C107.233 10.0897 106.47 10.8943 105.906 11.9124C105.358 12.9141 105.085 14.0472 105.085 15.3116C105.085 16.5924 105.358 17.7419 105.906 18.76C106.47 19.7617 107.233 20.5581 108.195 21.1493Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M123.899 22.036C122.572 22.036 121.395 21.7568 120.366 21.1985C119.354 20.6238 118.558 19.8438 117.978 18.8585C117.397 17.8568 117.107 16.7156 117.107 15.4347C117.107 14.4166 117.264 13.4971 117.58 12.676C117.911 11.8385 118.368 11.116 118.948 10.5084C119.529 9.90084 120.217 9.43284 121.013 9.10442C121.826 8.776 122.713 8.61179 123.675 8.61179C124.505 8.61179 125.284 8.76779 126.014 9.07979C126.744 9.37537 127.374 9.80231 127.905 10.3606C128.436 10.9025 128.842 11.5512 129.124 12.3065C129.406 13.0619 129.539 13.8912 129.522 14.7943L129.497 15.8042H119.291C119.351 16.5598 119.543 17.2331 119.869 17.824C120.267 18.5301 120.831 19.0802 121.561 19.4743C122.29 19.8684 123.136 20.0655 124.098 20.0655C124.795 20.0655 125.434 19.9505 126.014 19.7206C126.611 19.4907 127.225 19.0966 127.855 18.5383L128.95 20.0655C128.552 20.4596 128.071 20.8044 127.507 21.1C126.96 21.3956 126.371 21.6255 125.74 21.7897C125.127 21.9539 124.513 22.036 123.899 22.036ZM119.769 12.7745C119.586 13.1562 119.453 13.5832 119.371 14.0554H127.308V13.8829C127.275 13.2425 127.084 12.676 126.736 12.1834C126.404 11.6907 125.964 11.3048 125.417 11.0257C124.886 10.7301 124.306 10.5823 123.675 10.5823C122.73 10.5823 121.925 10.7712 121.262 11.1488C120.615 11.5265 120.117 12.0684 119.769 12.7745Z"
                  fill="#21111A"
                />
                <path d="M133.357 3.56231H131.192V21.7897H133.357V3.56231Z" fill="#21111A" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M141.578 22.036C140.251 22.036 139.073 21.7568 138.045 21.1985C137.033 20.6238 136.237 19.8438 135.656 18.8585C135.076 17.8568 134.786 16.7156 134.786 15.4347C134.786 14.4166 134.943 13.4971 135.258 12.676C135.59 11.8385 136.046 11.116 136.627 10.5084C137.207 9.90084 137.896 9.43284 138.692 9.10442C139.505 8.776 140.392 8.61179 141.354 8.61179C142.183 8.61179 142.963 8.76779 143.693 9.07979C144.423 9.37537 145.053 9.80231 145.584 10.3606C146.114 10.9025 146.521 11.5512 146.803 12.3065C147.085 13.0619 147.217 13.8912 147.201 14.7943L147.176 15.8042H136.97C137.029 16.5598 137.222 17.2331 137.547 17.824C137.945 18.5301 138.509 19.0802 139.239 19.4743C139.969 19.8684 140.815 20.0655 141.777 20.0655C142.474 20.0655 143.112 19.9505 143.693 19.7206C144.29 19.4907 144.904 19.0966 145.534 18.5383L146.629 20.0655C146.231 20.4596 145.75 20.8044 145.186 21.1C144.638 21.3956 144.049 21.6255 143.419 21.7897C142.805 21.9539 142.192 22.036 141.578 22.036ZM137.448 12.7745C137.264 13.1562 137.131 13.5832 137.049 14.0554H144.987V13.8829C144.953 13.2425 144.763 12.676 144.414 12.1834C144.083 11.6907 143.643 11.3048 143.096 11.0257C142.565 10.7301 141.984 10.5823 141.354 10.5823C140.409 10.5823 139.604 10.7712 138.941 11.1488C138.294 11.5265 137.796 12.0684 137.448 12.7745Z"
                  fill="#21111A"
                />
                <path
                  d="M148.697 8.8581V21.7897H150.911V14.4248C150.911 13.9486 150.994 13.5053 151.16 13.0947C151.342 12.6678 151.591 12.2901 151.906 11.9617C152.222 11.6333 152.586 11.3787 153.001 11.1981C153.432 11.0011 153.897 10.9025 154.394 10.9025C154.61 10.9025 154.834 10.9354 155.066 11.0011C155.315 11.0503 155.547 11.116 155.763 11.1981L156.335 8.8581C156.186 8.79242 155.978 8.73495 155.713 8.68568C155.464 8.63642 155.199 8.61179 154.917 8.61179C154.253 8.61179 153.631 8.76779 153.051 9.07979C152.47 9.37537 151.973 9.77768 151.558 10.2867C151.291 10.6141 151.073 10.9618 150.902 11.33L150.886 8.8581H148.697Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M164.528 21.7897V20.0529C164.364 20.2302 164.19 20.3986 164.006 20.5581C163.475 21.0179 162.895 21.3792 162.264 21.6419C161.634 21.9046 160.97 22.036 160.274 22.036C159.428 22.036 158.673 21.8718 158.01 21.5434C157.346 21.2149 156.815 20.7634 156.417 20.1886C156.036 19.5975 155.845 18.9078 155.845 18.1196C155.845 17.4627 155.969 16.8798 156.218 16.3707C156.467 15.8617 156.824 15.4429 157.288 15.1145C157.769 14.7697 158.366 14.5152 159.08 14.3509C159.809 14.1703 160.647 14.08 161.592 14.08H164.528V13.2425C164.528 12.4543 164.238 11.8221 163.658 11.3459C163.094 10.8533 162.364 10.6069 161.468 10.6069C160.688 10.6069 159.984 10.7629 159.353 11.0749C158.723 11.3869 158.159 11.8632 157.661 12.5036L156.243 11.0996C156.99 10.2621 157.811 9.6381 158.706 9.22758C159.602 8.81705 160.572 8.61179 161.617 8.61179C162.613 8.61179 163.475 8.776 164.205 9.10442C164.935 9.43284 165.499 9.91726 165.897 10.5577C166.295 11.1817 166.494 11.9371 166.494 12.8238V21.7897H164.528ZM164.528 15.8288V18.0354C164.306 18.3617 164.041 18.6607 163.732 18.9324C163.284 19.3101 162.77 19.6139 162.19 19.8438C161.626 20.0737 161.02 20.1886 160.373 20.1886C159.892 20.1886 159.469 20.1065 159.104 19.9423C158.739 19.7617 158.449 19.5154 158.234 19.2034C158.018 18.8749 157.91 18.5055 157.91 18.0949C157.91 17.7173 157.977 17.3971 158.109 17.1343C158.258 16.8552 158.482 16.6171 158.781 16.42C159.08 16.2065 159.436 16.0587 159.851 15.9766C160.265 15.8781 160.747 15.8288 161.294 15.8288H164.528Z"
                  fill="#21111A"
                />
                <path
                  d="M170.304 10.9518H167.418V8.8581H170.304V5.55747H172.469V8.8581H175.654V10.9518H172.469V21.7897H170.304V10.9518Z"
                  fill="#21111A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M182.448 22.036C181.171 22.036 180.026 21.7486 179.014 21.1739C178.019 20.5827 177.231 19.7863 176.651 18.7846C176.07 17.7665 175.78 16.6088 175.78 15.3116C175.78 14.0143 176.07 12.8648 176.651 11.8632C177.231 10.8615 178.019 10.0733 179.014 9.49853C180.026 8.90737 181.171 8.61179 182.448 8.61179C183.725 8.61179 184.861 8.90737 185.856 9.49853C186.868 10.0733 187.656 10.8615 188.22 11.8632C188.801 12.8648 189.091 14.0143 189.091 15.3116C189.091 16.6088 188.801 17.7665 188.22 18.7846C187.656 19.7863 186.868 20.5827 185.856 21.1739C184.861 21.7486 183.725 22.036 182.448 22.036ZM182.448 20.0655C183.31 20.0655 184.073 19.8602 184.737 19.4497C185.417 19.0392 185.948 18.4726 186.329 17.7501C186.711 17.0276 186.893 16.2147 186.877 15.3116C186.893 14.392 186.711 13.5792 186.329 12.8731C185.948 12.1669 185.417 11.6086 184.737 11.1981C184.073 10.7876 183.31 10.5823 182.448 10.5823C181.585 10.5823 180.814 10.7876 180.134 11.1981C179.454 11.6086 178.923 12.1752 178.542 12.8977C178.16 13.6038 177.978 14.4084 177.994 15.3116C177.978 16.2147 178.16 17.0276 178.542 17.7501C178.923 18.4726 179.454 19.0392 180.134 19.4497C180.814 19.8602 181.585 20.0655 182.448 20.0655Z"
                  fill="#21111A"
                />
                <path
                  d="M190.614 8.8581V21.7897H192.829V14.4248C192.829 13.9486 192.912 13.5053 193.078 13.0947C193.26 12.6678 193.509 12.2901 193.824 11.9617C194.139 11.6333 194.504 11.3787 194.919 11.1981C195.35 11.0011 195.814 10.9025 196.312 10.9025C196.528 10.9025 196.752 10.9354 196.984 11.0011C197.233 11.0503 197.465 11.116 197.68 11.1981L198.253 8.8581C198.103 8.79242 197.896 8.73495 197.631 8.68568C197.382 8.63642 197.117 8.61179 196.835 8.61179C196.171 8.61179 195.549 8.76779 194.968 9.07979C194.388 9.37537 193.89 9.77768 193.476 10.2867C193.209 10.6141 192.99 10.9618 192.82 11.33L192.804 8.8581H190.614Z"
                  fill="#21111A"
                />
                <path
                  d="M36.9276 4.51071C38.1364 2.33149 36.3869 -0.337211 33.993 0.0350303L2.46393 4.94157C-0.904665 5.46623 -0.784675 10.5545 2.61206 10.9077L16.6479 12.3659C21.6578 12.8876 24.8442 18.1884 23.0977 23.0978C22.4429 24.9356 24.9316 26.149 25.8737 24.4505L36.9276 4.51071Z"
                  fill="#01A4A4"
                />
              </svg>
            </Col>
            {isSetPasswordPage && (
              <Col lg={12}>
                {!resetToken && <SetPassword
                  ref={this.passwordPage}
                  isSuccess={isSetPasswordSuccessful}
                  isProcessing={buttonLoading}
                />}
                {resetToken && <Row style={{ height: '75vh' }}>
                  <Col xs={12} className="text-center my-auto">
                    <Loading />
                  </Col>
                </Row>}
              </Col>
            )}
            {question?.IsFinalPage && !isFinalPage && (
              <QuizComplete
                firstName={candidateData.FirstName}
                lastName={candidateData.LastName}
                resultMessage={question.Content}
              />
            )}
            {!question?.IsFinalPage && question?.InputType === 'RadioGroup' && (
              <>
                <Col lg={12} className="text-center my-4">
                  <h1 className="font-weight-bold">{question?.Content}</h1>
                </Col>
                <Col lg={12}>
                  <Row>
                    {
                      question?.Answers && question?.Answers.map((answer) => (
                        <Col lg={{ size: 4, offset: 4 }} key={answer.Value}>
                          <CustomRadioGroup
                            name="radio"
                            answer={answer}
                            onChange={this.handleChangeCheckbox}
                            checked={choose ? choose === answer.Value : answer.IsDefault}
                            id={answer.Value}
                            childAnswers={childAnswers}
                            question={question}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </Col>
              </>
            )}
            {!question?.IsFinalPage && question?.InputType === 'TextBoxMultiple' && (
              <>
                <Col lg={12} className="text-center my-4">
                  <h1 className="font-weight-bold">{question?.Content}</h1>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={{ size: 4, offset: 4 }}>
                      <ChipInput
                        name={question.id}
                        value={answers}
                        onChange={this.handleChangeTextBoxMultiple}
                        placeholder={question.Placeholder}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            {!question?.IsFinalPage && question?.InputType === 'TextBox' && (
              <>
                <Col lg={12} className="text-center my-4">
                  <h1 className="font-weight-bold">{question?.Content}</h1>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={{ size: 4, offset: 4 }}>
                      <Input
                        className="mb-2"
                        style={{ height: '3rem' }}
                        placeholder={question?.Placeholder}
                        onChange={this.handleChangeTextBox}
                        value={textAnswer}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
          <Row><Col xs={12} className="my-5"><span>&nbsp;</span></Col></Row>
          <Row className="fixed-bottom text-center bg-white">
            <Col lg={12} className="mb-3 mt-4">
              {!question?.IsFinalPage && !isSetPasswordPage && (
                <Row>
                  <Col xs={12} className="px-5 px-lg-0">
                    <Button className="font-weight-bold bg-primary text-white btn btn-primary JA-button-primary py-2 JA-Onboard-Quiz-Next-Button" disabled={buttonLoading || isDisabledNextStep} onClick={() => this.handleNext()}>{buttonLoading ? 'Processing...' : 'Next'}</Button>
                  </Col>
                  {!isFirstQuestion && (
                    <Col xs={12} className="px-5 px-lg-0">
                      <Button onClick={() => this.handleGoBack()} className="mt-2 bg-white border-0 JA-Go-Back-Button" disabled={buttonLoading}>Go back</Button>
                    </Col>
                  )}
                </Row>
              )}
              {question?.IsFinalPage && !isSetPasswordPage && (
                <Row>
                  <Col xs={12} className="px-5 px-lg-0">
                    <Button
                      className="font-weight-bold bg-primary text-white btn btn-primary JA-button-primary py-2 JA-Onboard-Quiz-Next-Button"
                      disabled={buttonLoading}
                      onClick={candidateData.IsLoggedIn || candidateData.isSetPassword
                        ? this.handleRedirectToPortal
                        : this.handleRedirectToSetPasswordPage}
                    >
                      {buttonLoading ? 'Processing...' : 'View your workspace'}
                    </Button>
                  </Col>
                </Row>
              )}
              {isSetPasswordPage && !isSetPasswordSuccessful && (
                <Row>
                  <Col xs={12} className="px-5 px-lg-0">
                    <Button className="font-weight-bold bg-primary text-white btn btn-primary JA-button-primary py-2 JA-Onboard-Quiz-Next-Button" disabled={buttonLoading} onClick={this.handleSavePassword}>{buttonLoading ? 'Processing...' : 'Create password'}</Button>
                  </Col>
                </Row>
              )}
              {isSetPasswordPage && isSetPasswordSuccessful && (
                <Row>
                  <Col xs={12} className="px-5 px-lg-0">
                    <Button className="font-weight-bold bg-primary text-white btn btn-primary JA-button-primary py-2 JA-Onboard-Quiz-Next-Button" disabled={buttonLoading} onClick={this.handleRedirectToPortal}>Go to your Dashboard</Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Quiz;
