/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/**
 * Props
 *
 * label:                       the name is showed on UI
 * name:                        the column name in DB
 *   + If the field is a field in embedded object than the format should be <embedded_object_name>-<name>
 *
 * lowerCaseField:              lowercase field in DB of this field
 * className:                   the classes will be applied to input
 * errorMessage:                the error message. It is showed below the input
 * description:                 the description of input. It is showed below the input
 * iconTooltipMessage:          the icon tooltip of input
 * inputRef:                    the ref of input
 * required:                    specify the input is required or not (for validation)
 * disabled:                    make the input is disable
 * onChange:                    onChange handler
 */
import React, {
  useState,
  useLayoutEffect,
  useEffect
} from 'react';
import {
  UncontrolledTooltip,
  Input
} from 'reactstrap';
import {
  showNotification,
  validURL
} from '../../../helper/helper';
import Icon from '../Icon';
import EllipsisText from '../EllipsisText';
import './custom.scss';
import variables from '../../../variables.scss';

const INPUT_TYPE_TEXT = 'text';
const INPUT_TYPE_NUMBER = 'number';

function FloatingLabelInput(props) {
  const {
    label = 'Enter...',
    title = '',
    name = 'input',
    value = '',
    lowerCaseField = '',
    className = '',
    errorMessage = '',
    description = '',
    iconTooltipMessage = '',
    icon = null,
    customIcon = null,
    inputRef = null,
    required = false,
    disabled = false,
    isReadOnly = false,
    isTag = false,
    tagClass = '',
    tagType = '',
    isOnChangeOff = false,
    isGetValueManually = false,
    type = INPUT_TYPE_TEXT,
    onChange,
    onKeyDown,
    onClick,
    maxLength = null,
    minNumber = 0,
    maxNumber = null,
    placement
  } = props;
  const activeClass = 'input-active';
  const requiredClass = required ? 'input-required' : '';
  const disabledClass = disabled ? 'input-disabled' : '';
  const errorClass = errorMessage && errorMessage !== '' ? 'input-error' : '';
  const multipleIcons = (type === 'number' && (iconTooltipMessage || icon)) ? 'floating-label-input-wrapper--multiple-icons' : '';
  const classes = `${requiredClass} ${disabledClass} ${errorClass} ${className} ${multipleIcons}`;
  const errorToastID = 'floatingLabeInput-error';

  const [inputValue, setInputValue] = useState(value);
  const [tagValues, setTagValues] = useState([]);

  useEffect(() => {
    if (isTag) {
      setTagValues(value);
      setInputValue('');
    } else {
      setInputValue(value);
    }
  }, [value, isTag]);

  useLayoutEffect(() => {
    try {
      const inputEl = inputRef.current;
      if (inputValue !== '') {
        if (!inputEl.classList.contains(activeClass)) {
          inputEl.classList.add(activeClass);
        }
      } else {
        inputEl.classList.remove(activeClass);
      }
    } catch (err) {
      showNotification(err.message, 'error', errorToastID);
    }
    return () => null;
  }, [inputValue, inputRef]);

  const handleFieldValue = (currentValue) => {
    let newValue = currentValue.trim();
    if (type === INPUT_TYPE_NUMBER) {
      // eslint-disable-next-line radix
      newValue = Number.isNaN(currentValue) ? newValue : parseInt(newValue);
    }
    return newValue;
  };

  const handleOnChange = (e) => {
    try {
      const { value: elValue } = e.target;
      setInputValue(elValue);
      // Pass value back
      if (!isTag && !isGetValueManually && onChange) {
        let res = {
          fieldName: name,
          fieldValue: handleFieldValue(elValue)
        };
        if (lowerCaseField) {
          res = { ...res, lowerCaseField };
        }
        onChange(res.fieldValue, res.fieldName, res.lowerCaseField);
      }
    } catch (err) {
      showNotification(err.message, 'error', errorToastID);
    }
    return false;
  };

  const handleUpDownArrow = (arrowType) => {
    if (arrowType === 'up') {
      inputRef.current.stepUp();
    } else {
      inputRef.current.stepDown();
    }
    onChange({
      fieldName: name,
      fieldValue: handleFieldValue(inputRef.current.value)
    });
  };

  const handleOnFocus = (e) => {
    if (e.target.value === '' && !isOnChangeOff) {
      e.target.classList.add(activeClass);
    }
  };

  const handleOnBlur = (e) => {
    if (e.target.value === '') {
      e.target.classList.remove(activeClass);
    }
  };

  const handleOnKeyDown = (e) => {
    const { keyCode } = e;
    if (onKeyDown) {
      onKeyDown({ keyCode, fieldName: name });
    }

    // If input is a tag
    if (isTag && keyCode === 13) {
      if (!inputValue.trim()) {
        return true;
      }
      setInputValue('');
      if (tagValues && tagValues.length > 0) {
        const isExited = tagValues.some((item) => item.toLowerCase().trim() === inputValue.toLowerCase().trim());
        if (isExited) {
          return true;
        }
      }

      // Validation
      if (tagType === 'link' && !validURL(inputValue.trim())) {
        return true;
      }

      const newTagValues = [...tagValues, inputValue.trim()];
      setTagValues(newTagValues);
      const res = {
        fieldName: name,
        fieldValue: newTagValues
      };
      onChange(res);
    } else if (isGetValueManually && keyCode === 13) {
      const res = {
        fieldName: name,
        fieldValue: handleFieldValue(e.target.value)
      };
      onChange(res);
      setInputValue('');
    }

    return true;
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick({ fieldName: name });
      if (isOnChangeOff) {
        inputRef.current.blur();
      }
    }
  };

  const handleOnRemoveTagValue = (index) => {
    const newTagValues = [...tagValues];
    newTagValues.splice(index, 1);
    setTagValues(newTagValues);
    const res = {
      fieldName: name,
      fieldValue: newTagValues
    };
    onChange(res);
  };

  const renderTagValueType = (item, key) => {
    let firstIcon = null;
    let secondIcon = null;

    switch (tagType) {
      case 'link':
        firstIcon = <Icon solid name="faLink" wrapperClass="px-1 mr-1" color={variables['purplish-800']} />;
        secondIcon = <Icon solid wrapperClass="px-1 pl-2 ml-auto font-size-16" className="bg-white rounded" name="faCheckCircle" color={variables['green-300']} />;
        break;
      default:
        break;
    }

    return (
      <>
        {firstIcon}
        <EllipsisText id={`ca-input-tag-value-${key}`}>{item}</EllipsisText>
        {secondIcon}
        <Icon solid name="faTimes" wrapperClass={`px-1 cursor-pointer ${tagType ? '' : 'ml-3'}`} color={variables['neutral-500']} onClick={() => handleOnRemoveTagValue(key)} />
      </>
    );
  };

  const renderTagValues = () => {
    if (tagValues.length === 0) {
      return null;
    }

    const render = tagValues.map((item, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className={`d-flex ca-input-tag-value ${tagType ? 'w-100' : ''} ${tagClass}`} key={key}>
        {renderTagValueType(item, key)}
      </div>
    ));

    return (<div className="d-flex flex-wrap mt-2">{render}</div>);
  };

  return (
    <div className={`w-100 floating-label-input__outer-wrapper ${isReadOnly ? 'input-read-only' : ''}`}>
      {
        title.length > 0
          ? <p className="font-weight-500 font-size-14 mb-1 text-left">{title}</p>
          : null
      }
      <div className={`floating-label-input-wrapper ${classes}`}>
        <Input
          readOnly={isOnChangeOff || disabled || isReadOnly}
          id={name}
          innerRef={inputRef}
          type={type}
          min={minNumber}
          max={maxNumber}
          name={name}
          tabIndex={disabled || isReadOnly ? '-1' : '0'}
          value={inputValue}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={isOnChangeOff ? null : handleOnChange}
          onKeyDown={handleOnKeyDown}
          onClick={handleOnClick}
          required={required}
          maxLength={maxLength}
        />
        <label htmlFor={name}>{label}</label>
        <div className="floating-label-input-icon__wrapper">
          {
            type === 'number' && (
              <div className="floating-label-input-icon__up-down-arrow">
                <i className="d-flex floating-label-input-icon" id="fi-icon-faChevronUp" onClick={() => handleUpDownArrow('up')} aria-hidden="true">
                  <Icon solid name="faChevronUp" />
                </i>
                <i className="d-flex floating-label-input-icon" id="fi-icon-faChevronDown" onClick={() => handleUpDownArrow('down')} aria-hidden="true">
                  <Icon solid name="faChevronDown" />
                </i>
              </div>
            )
          }
          {
            iconTooltipMessage && (
              <>
                <i className="d-flex floating-label-input-icon" id={`fi-icon-${name}`}>
                  <Icon solid name="faInfoCircle" />
                </i>
                <UncontrolledTooltip fade={false} placement={placement || 'auto'} target={`fi-icon-${name}`}>
                  {iconTooltipMessage}
                </UncontrolledTooltip>
              </>
            )
          }
          {
            (!iconTooltipMessage && icon) && (
              <i className="d-flex floating-label-input-icon" id={`fi-icon-${name}`}>
                <Icon solid name={icon} color={variables['purplish-800']} />
              </i>
            )
          }
          {
            (!iconTooltipMessage && customIcon) && (
              <i
                className={`d-flex floating-label-input-icon ${isGetValueManually && 'cursor-pointer'}`}
                id={`fi-icon-${name}`}
                onClick={() => {
                  if (isGetValueManually) {
                    const res = { fieldName: name, fieldValue: handleFieldValue(inputValue) };
                    onChange(res);
                    setInputValue('');
                  }
                }}
                aria-hidden="true"
              >
                {customIcon}
              </i>
            )
          }
        </div>
      </div>
      {(!errorMessage && description !== '') && <div className="input-description">{description}</div>}
      {errorMessage && errorMessage !== '' && <div className="error-message">{errorMessage}</div>}
      {isTag && tagValues && renderTagValues()}
    </div>
  );
}

export default FloatingLabelInput;
