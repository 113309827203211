/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { getAPIEndpoint } from '../../helper/helper';
import ErrorPage from '../ErrorPage';
import HeaderFeedback from '../Common/HeaderFeedback';
import RatingPoint from '../Common/RatingPoint';
import RatingNumber from '../Common/RatingNumber';
import FloatingSelect from '../Common/FloatingSelect';
import FloatingLabelInput from '../Common/FloatingLabelInput';
import FloatingDatePicker from '../Common/FloatingDatePicker';
import Loading from '../Common/Loading';
import ModalDefault from '../Common/Modal';
import MultipleSelect from '../Common/MultipleSelect';
import EmptyStar from '../../assets/images/empty_star.png';
import FullStar from '../../assets/images/full_star.png';
import {
  identify, load, track, pageView
} from '../../helper/rudderStackService';
import 'react-toastify/dist/ReactToastify.css';
import './custom.scss';
import {
  CANCEL_CANDIDATE_FINAL_FEEDBACK, CANCEL_CANDIDATE_FINAL_FEEDBACK_KEY, CANCEL_CANDIDATE_FINAL_FEEDBACK_RESUBMIT, CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK,
  CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY, CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT, PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK, PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_KEY,
  PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_RESUBMIT, PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK, PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY, PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT,
  SUBMIT_CANDIDATE_FINAL_FEEDBACK, SUBMIT_CANDIDATE_FINAL_FEEDBACK_KEY, SUBMIT_CANDIDATE_FINAL_FEEDBACK_RESUBMIT, SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK,
  SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY, SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT, CANDIDATE_ALREADY_SUBMITTED_WORKSHOP_FEEDBACK, CANDIDATE_ALREADY_SUBMITTED_FIRST_SESSION_FEEDBACK,
  CANCEL_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY, CANCEL_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY, PAGE_VIEW_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY, PAGE_VIEW_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY,
  SUBMIT_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY, SUBMIT_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY, PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT, SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_KEY,
  SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK, SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT, CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_KEY, CANCEL_CANDIDATE_WORKSHOP_FEEDBACK, CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT,
  PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_KEY, PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK, PAGE_VIEW_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY, CANCEL_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY,
  SUBMIT_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY
} from '../Constants/common';

class Feedback extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      feedbackData: [],
      isError: false,
      errorMessage: '',
      isLoading: true,
      title: 'Review your experience',
      values: {},
      isDisable: true,
      subQuestions: [],
      isSubmitted: false,
      isOpenDialog: true,
      isOpenCancelModal: false,
      isShowText: false,
      feedbackID: null,
      workshopID: null,
      workshopName: null,
      workshopEventID: null,
      redirectURL: ''
    };
  }

  componentDidMount() {
    const { state } = this;
    const { feedbackData } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const fullUrl = window.location.href;
    const token = urlParams.get('token');
    const questionID = urlParams.get('questionID');
    const rate = Number(urlParams.get('rate'));
    const utmSource = urlParams.get('utm_source');
    const utmMediumFromUrl = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    if (!feedbackData || feedbackData.length === 0) {
      axios.get(`${getAPIEndpoint(fullUrl)}feedback?token=${token}`)
        .then((res) => {
          const response = res.data;
          if (response.statusCode === 200) {
            // rudderstack implement
            const { rudderStackData } = response.data;
            load(rudderStackData.writeKey, rudderStackData.dataPlanUrl);
            identify(rudderStackData.identifyData.user_id, {
              ...rudderStackData.identifyData,
              utm_source: utmSource,
              utm_medium: utmMediumFromUrl,
              utm_campaign: utmCampaign
            });
            const eventName = this.generateEventName('view');
            pageView(eventName);
            // feedback implement
            const {
              feedbackData: data, feedbackID, workshopID, workshopName, workshopEventID
            } = response.data;
            const allChildQuestions = data.reduce((accumulator, currentValue) => {
              const { childQuestions } = currentValue;
              return [...accumulator, ...childQuestions];
            }, []);
            const subQuestions = allChildQuestions.reduce((accumulator, currentValue) => {
              const { dependsOnQuestion } = currentValue;
              if (dependsOnQuestion?.length > 0) {
                return [...accumulator, ...dependsOnQuestion];
              }
              return accumulator;
            }, []);
            const initValues = allChildQuestions.reduce((accumulator, currentValue) => {
              const {
                id, DefaultValue, IsRequired, InputType, DateFormat, Order, PointScale, value
              } = currentValue;

              let defaultValue = DefaultValue;
              let pointScale = null;
              if ((InputType === 'RATING' || InputType === 'POINT') && defaultValue) {
                defaultValue = Number(defaultValue);
                pointScale = PointScale;
              }
              if (InputType === 'MULTIPLE_SELECT' && !defaultValue) defaultValue = [];
              return ({
                ...accumulator,
                [id]: {
                  value: value || defaultValue,
                  isRequired: IsRequired,
                  dateFormat: DateFormat,
                  order: Order,
                  pointScale
                }
              });
            }, {});
            // add rate from url to question
            const isHaveKey = Object.prototype.hasOwnProperty.call(initValues, questionID);
            if (questionID && rate && isHaveKey) {
              const question = allChildQuestions.find((item) => item.id === questionID);
              const { Point: maxPoint } = question;
              const isValidRate = maxPoint && (rate > 0 && rate <= maxPoint);
              if (isValidRate) initValues[questionID].value = rate;
            }
            this.setState({
              ...state,
              feedbackData: data,
              isLoading: false,
              values: initValues,
              subQuestions,
              feedbackID,
              workshopID,
              workshopName,
              workshopEventID
            });
          } else if (response.statusCode === 201) {
            let title = 'The feedback has been submitted and no longer available';
            if (response.message === CANDIDATE_ALREADY_SUBMITTED_WORKSHOP_FEEDBACK || response.message === CANDIDATE_ALREADY_SUBMITTED_FIRST_SESSION_FEEDBACK) {
              title = 'Thank you for your feedback';
            }
            this.setState({
              ...state,
              isSubmitted: true,
              isShowText: true,
              isLoading: false,
              title
            });
          } else {
            this.setState({
              ...state,
              isError: true,
              errorMessage: response.message,
              isLoading: false
            });
          }
        }).catch((error) => {
          this.setState({
            ...state,
            isError: true,
            isLoading: false,
            errorMessage: error.message
          });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.state;
    if (values !== prevState.values) {
      const isError = this.validate();
      this.setState((prev) => ({
        ...prev,
        isDisable: isError
      }));
    }
  }

  trackingDataSubmit = async (feedbackData, dataSubmit, id) => {
    const isResend = this.checkIsResend();
    const allChildQuestions = feedbackData.reduce((accumulator, currentValue) => {
      const { childQuestions } = currentValue;
      return [...accumulator, ...childQuestions];
    }, []);
    const subQuestions = allChildQuestions.reduce((accumulator, currentValue) => {
      const { dependsOnQuestion } = currentValue;
      if (dependsOnQuestion?.length > 0) {
        return [...accumulator, ...dependsOnQuestion];
      }
      return accumulator;
    }, []);
    const rudderStackData = {};
    dataSubmit.forEach((data) => {
      const childQuestion = allChildQuestions.find((x) => x.id === data.QuestionID);
      const subQuestion = subQuestions.find((x) => x.id === data.QuestionID);
      const question = childQuestion ? childQuestion.Title : subQuestion.Title;
      const answer = `${data.Value}`;
      const formatQuestion = question.split(' ').join('_');
      rudderStackData[`${formatQuestion}`] = answer;
    });
    const { isLowRating } = this.handleCheckLowRating(dataSubmit, 'Candidate Final Feedback', allChildQuestions);
    let status = isResend ? 'RESUBMITTED_ACTION_NOT_REQUIRED' : 'NONE';
    if (isLowRating) {
      status = isResend ? 'RESUBMITTED_ACTION_REQUIRED' : 'ACTION_REQUIRED';
    }
    rudderStackData.feedback_id = id;
    const eventName = this.generateEventName('submit');
    const { workshopID, workshopName, workshopEventID } = this.state;
    const eventData = { moderation_status: status, feedbackEntity: rudderStackData };
    if (workshopEventID) eventData.workshopEntity = { workshopId: workshopID, workshopName, workshopEventId: workshopEventID };
    track(eventName, eventData);
  };

  handleChange = (value, name, isHaveSubQuestion) => {
    const { subQuestions, values } = this.state;
    const formatValue = value?.value || value; // case select
    if (isHaveSubQuestion) {
      const subQuestion = subQuestions.find((question) => question.DependsOn === name && question.DependsValue === formatValue);
      const newValues = { ...values };
      newValues[name].value = value;
      subQuestions.forEach((question) => {
        const isHaveKey = Object.prototype.hasOwnProperty.call(newValues, question.id);
        if (question.DependsOn === name && isHaveKey) {
          delete newValues[question.id];
        }
      });
      if (subQuestion) {
        const isHaveKey = Object.prototype.hasOwnProperty.call(newValues, subQuestion.id);
        const dependsValue = subQuestion?.DependsValue;
        // add sub question
        if (dependsValue === formatValue && !isHaveKey) {
          newValues[subQuestion.id] = {
            value: subQuestion.defaultValue,
            isRequired: subQuestion.IsRequired,
            dateFormat: subQuestion.DateFormat,
            order: subQuestion.Order
          };
        }
      }
      this.setState((prevState) => ({
        ...prevState,
        values: newValues
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [name]: {
            ...prevState.values[name],
            value
          }
        }
      }));
    }
  };

  validate = () => {
    const { values } = this.state;
    const arr = [];
    for (const property in values) {
      if (Object.prototype.hasOwnProperty.call(values, property)) {
        if (values[property].isRequired && !values[property].value) {
          arr.push(values[property]);
        }
      }
    }
    return arr.length > 0;
  };

  checkIsResend = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign');
    return utmCampaign.startsWith('resend');
  };

  generateEventName = (action) => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCampaign = urlParams.get('utm_campaign');
    const isResend = this.checkIsResend();
    const event = `${action}_${utmCampaign}`;
    switch (event) {
      // candidate final feedback event
      case event.includes(PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_KEY) || event.includes(PAGE_VIEW_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY) ? event : '':
        return isResend ? PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK_RESUBMIT : PAGE_VIEW_CANDIDATE_FINAL_FEEDBACK;
      case event.includes(SUBMIT_CANDIDATE_FINAL_FEEDBACK_KEY) || event.includes(SUBMIT_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY) ? event : '':
        return isResend ? SUBMIT_CANDIDATE_FINAL_FEEDBACK_RESUBMIT : SUBMIT_CANDIDATE_FINAL_FEEDBACK;
      case event.includes(CANCEL_CANDIDATE_FINAL_FEEDBACK_KEY) || event.includes(CANCEL_RESEND_CANDIDATE_FINAL_FEEDBACK_KEY) ? event : '':
        return isResend ? CANCEL_CANDIDATE_FINAL_FEEDBACK_RESUBMIT : CANCEL_CANDIDATE_FINAL_FEEDBACK;
      // candidate first session feedback event
      case event.includes(PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) || event.includes(PAGE_VIEW_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) ? event : '':
        return isResend ? PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT : PAGE_VIEW_CANDIDATE_FIRST_SESSION_FEEDBACK;
      case event.includes(SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) || event.includes(SUBMIT_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) ? event : '':
        return isResend ? SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT : SUBMIT_CANDIDATE_FIRST_SESSION_FEEDBACK;
      case event.includes(CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) || event.includes(CANCEL_RESEND_CANDIDATE_FIRST_SESSION_FEEDBACK_KEY) ? event : '':
        return isResend ? CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK_RESUBMIT : CANCEL_CANDIDATE_FIRST_SESSION_FEEDBACK;
      // candidate workshop feedback event
      case event.includes(PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_KEY) || event.includes(PAGE_VIEW_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY) ? event : '':
        return isResend ? PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT : PAGE_VIEW_CANDIDATE_WORKSHOP_FEEDBACK;
      case event.includes(SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_KEY) || event.includes(SUBMIT_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY) ? event : '':
        return isResend ? SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT : SUBMIT_CANDIDATE_WORKSHOP_FEEDBACK;
      case event.includes(CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_KEY) || event.includes(CANCEL_RESEND_CANDIDATE_WORKSHOP_FEEDBACK_KEY) ? event : '':
        return isResend ? CANCEL_CANDIDATE_WORKSHOP_FEEDBACK_RESUBMIT : CANCEL_CANDIDATE_WORKSHOP_FEEDBACK;
      default:
        break;
    }
    return '';
  };

  handleCheckLowRating = (feedbackData, feedbackType, feedbackQuestions) => {
    let isLowRating = false;
    let lowestRating = 6;
    let typeQuestion = 'POINT'; // RATING for the NPS question, POINT for the star question
    switch (feedbackType) {
      case 'Candidate Final Feedback': {
        const appendFeedbackData = feedbackData.map((data) => {
          const question = feedbackQuestions.find((x) => x.id === data.QuestionID);
          return { ...data, InputType: question?.InputType };
        });
        appendFeedbackData.forEach((question) => {
          if (question.InputType === 'POINT' && question.ValueNumber && question.ValueNumber > 0 && question.ValueNumber <= 3) {
            if (question.ValueNumber < lowestRating) {
              lowestRating = question.ValueNumber;
              typeQuestion = 'POINT';
            }
            isLowRating = true;
          }
          if (question.InputType === 'RATING' && question.Value && question.Value > 0 && question.Value <= 6) {
            if (question.Value <= lowestRating) {
              lowestRating = question.Value;
              typeQuestion = 'RATING';
            }
            isLowRating = true;
          }
        });
        break;
      }
      default:
        break;
    }
    return { isLowRating, lowestRating, typeQuestion };
  };

  renderSubQuestion = (question) => {
    const { values } = this.state;
    const {
      InputType: inputType, id: questionId
    } = question;

    if (inputType === 'DATE') {
      const { Placeholder: placeholder, DateRange: dateRange } = question;
      return (
        <Col xs={12} className="mt-3" key={questionId}>
          <FloatingDatePicker
            name={questionId}
            label={placeholder}
            onChange={this.handleChange}
            selected={values[questionId]?.value}
            maxDate={dateRange === 'PAST' ? new Date() : null}
            minDate={dateRange === 'FUTURE' ? new Date() : null}
          />
        </Col>
      );
    }
    if (inputType === 'TEXTFIELD') {
      const { Placeholder: placeholder } = question;
      const floatingInputRef = React.createRef();
      return (
        <Col key={questionId} xs={12} className="mt-3">
          <FloatingLabelInput
            name={questionId}
            label={placeholder}
            value={values[questionId]?.value}
            onChange={this.handleChange}
            inputRef={floatingInputRef}
          />
        </Col>
      );
    }
    return null;
  };

  renderQuestion = (question) => {
    const { values } = this.state;
    const {
      InputType: inputType, id: questionId, dependsOnQuestion
    } = question;
    const isHaveSubQuestion = dependsOnQuestion?.length > 0;
    if (inputType === 'RATING') {
      const { RateScale: rateScale, RateRange: rateRange, Title: questionTitle } = question;
      const arrRating = [];
      for (let i = rateRange[0]; i <= rateRange[1]; i += 1) {
        arrRating.push(i);
      }
      return (
        <Row className="mb-36" key={questionId}>
          <Col xs={12}>
            <p>{questionTitle}</p>
          </Col>
          <Col xs={12}>
            <RatingNumber
              initialRating={Number(values[questionId].value)}
              stringLowRate={rateScale[0]}
              stringHighRate={rateScale[1]}
              rateRange={arrRating}
              name={questionId}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
      );
    }
    if (inputType === 'POINT') {
      const { PointScale: pointScale, Title: questionTitle, Point: point } = question;
      return (
        <Row className="mb-36 align-items-center" key={questionId}>
          <Col md={6}>
            <p className="mb-0">{questionTitle}</p>
          </Col>
          <Col md={6} className="mt-3 mt-md-0">
            <RatingPoint
              className="ml-md-5 justify-content-md-end"
              initialRating={Number(values[questionId].value)}
              name={questionId}
              maxPoint={point}
              stringRatingMap={pointScale}
              stringRating={pointScale[values[questionId].value]}
              emptySymbol={<img src={EmptyStar} alt="empty_star" />}
              fullSymbol={<img src={FullStar} alt="full_star" />}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
      );
    }
    if (inputType === 'TEXTBOX') {
      const { Placeholder: placeholder } = question;
      const floatingInputRef = React.createRef();
      return (
        <Row className="mb-36" key={questionId}>
          <Col>
            <FloatingLabelInput
              name={questionId}
              label={placeholder}
              value={values[questionId].value}
              onChange={this.handleChange}
              inputRef={floatingInputRef}
            />
          </Col>
        </Row>
      );
    }
    if (inputType === 'SELECT') {
      const { Placeholder: placeholder, Options: options } = question;
      const formatOptions = options.map((option) => ({ value: option, label: option }));
      const formatValue = typeof values[questionId].value === 'string'
        ? { value: values[questionId].value, label: values[questionId].value }
        : values[questionId].value;
      return (
        <Row className="mb-36" key={questionId}>
          <Col>
            <FloatingSelect
              name={questionId}
              options={formatOptions}
              label={placeholder}
              value={formatValue}
              onChange={
                isHaveSubQuestion
                  ? (value, name) => this.handleChange(value, name, isHaveSubQuestion)
                  : this.handleChange
              }
            />
          </Col>
          {isHaveSubQuestion && dependsOnQuestion.map((subQuestion) => {
            const { DependsOn: dependsOn, DependsValue: dependsValue } = subQuestion;
            if (dependsOn && dependsValue && dependsValue === values[dependsOn]?.value?.value) {
              return (
                this.renderSubQuestion(subQuestion)
              );
            }
            return null;
          })}
        </Row>
      );
    }
    if (inputType === 'DATE') {
      const { Placeholder: placeholder, DateRange: dateRange } = question;
      return (
        <div className="mb-36" key={questionId}>
          <FloatingDatePicker
            name={questionId}
            label={placeholder}
            onChange={this.handleChange}
            selected={values[questionId]?.value}
            maxDate={dateRange === 'PAST' ? new Date() : null}
            minDate={dateRange === 'FUTURE' ? new Date() : null}
          />
        </div>
      );
    }
    if (inputType === 'MULTIPLE_SELECT') {
      const { Options: options } = question;
      return (
        <div className="mb-36" key={questionId}>
          <MultipleSelect
            name={questionId}
            listOptions={options}
            listActiveValue={values[questionId].value || []}
            isClickable
            onChange={this.handleChange}
          />
        </div>
      );
    }
    return null;
  };

  handleSubmit = async () => {
    const { values, feedbackData, feedbackID } = this.state;
    // format data submit
    const keys = Object.keys(values);
    const id = feedbackID || uuidv4();
    const dataUpdate = keys.map((key) => {
      let formatValue = values[key].value?.value || values[key].value;
      let valueNumber = null;
      if (values[key]?.dateFormat) {
        formatValue = moment(formatValue).format(values[key]?.dateFormat);
      }
      if (values[key]?.pointScale) {
        valueNumber = formatValue;
        formatValue = values[key]?.pointScale[valueNumber];
      }
      return ({
        id: key, value: formatValue || '', order: values[key]?.order, valueNumber
      });
    })
      .sort((a, b) => a.order - b.order)
      .map((item) => ({ QuestionID: item.id, Value: item.value, ValueNumber: item.valueNumber }));

    try {
      // call API update
      const urlParams = new URLSearchParams(window.location.search);
      const fullUrl = window.location.href;
      const token = urlParams.get('token');

      this.setState((prevState) => ({
        ...prevState,
        isLoading: true
      }));
      const response = await axios.post(`${getAPIEndpoint(fullUrl)}feedback`, { id, data: dataUpdate }, {
        headers: {
          Authorization: token
        }
      });
      const { data } = response;
      if (data.statusCode === 200) {
        this.setState((prevState) => ({
          ...prevState,
          isSubmitted: true,
          title: 'Thank you for your feedback',
          isLoading: false,
          redirectURL: data?.data?.redirectURL
        }));
        this.trackingDataSubmit(feedbackData, dataUpdate, id);
      } else {
        this.setState((prevState) => ({
          ...prevState,
          isError: true,
          isLoading: false,
          errorMessage: data.message
        }));
      }
    } catch (error) {
      this.setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
        errorMessage: error.message
      }));
    }
  };

  handleCancelFeedback = () => {
    const eventName = this.generateEventName('cancel');
    track(eventName);
    setTimeout(() => {
      window.open('about:blank', '_self');
      window.close();
    }, 500);
  };

  handleRedirectToPortal = () => {
    const { redirectURL } = this.state;
    window.location.href = redirectURL;
  };

  render() {
    const {
      isError, errorMessage, isLoading, title, feedbackData, isDisable, isSubmitted, isOpenDialog, isOpenCancelModal, isShowText, redirectURL
    } = this.state;

    const dataCancelModal = {
      title: 'Confirmation',
      content: 'Are you sure you want to cancel and close the feedback form? Any answers you have given will be lost',
      btnSuccess: 'Yes',
      btnCancel: 'No'
    };

    if (isError) {
      return (<ErrorPage message={errorMessage} />);
    }
    return (
      <>
        <Helmet>
          <title>Feedback</title>
        </Helmet>
        {isLoading ? (
          <div className="position-fixed inset-0 d-flex justify-content-center align-items-center z-99 bg-white">
            <Loading />
          </div>
        ) : (
          <>
            <HeaderFeedback text={title} isSubmitted={isSubmitted} />
            {!isSubmitted && (
              <Container className="feedback">
                {feedbackData && feedbackData.length > 0 && feedbackData.map((data) => {
                  const { Title: groupTitle, childQuestions } = data;
                  return (
                    <Row key={data.id} className="feedback-group">
                      <Col>
                        <p className="feedback-group-title font-weight-600 mb-36">{groupTitle}</p>
                        {childQuestions && childQuestions.length > 0 && childQuestions.map((question) => (
                          this.renderQuestion(question)
                        ))}
                      </Col>
                    </Row>
                  );
                })}

                <Row className="feedback-group">
                  <Col>
                    <Button className="feedback-group-btn__submit" onClick={this.handleSubmit} disabled={isDisable}>Submit your feedback</Button>
                    <Button
                      className="ml-3 feedback-group-btn__cancel"
                      onClick={() => this.setState((prevState) => ({ ...prevState, isOpenCancelModal: true }))}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <p className="feedback-note">Please note: This feedback may be shared with your employer and your coach</p>
                  </Col>
                </Row>
                {isOpenDialog && (
                  <div className="feedback-dialog">
                    <p>Your feedback helps us understand where we’ve helped and how we can improve. We thank you for your time.</p>
                    <Button onClick={() => this.setState((prevState) => ({ ...prevState, isOpenDialog: false }))}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L11 11" stroke="#211B21" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M11 1L1 11" stroke="#211B21" strokeWidth="1.5" strokeLinecap="round" />
                      </svg>
                    </Button>
                  </div>
                )}
              </Container>
            )}
            {isShowText && (
              <Container>
                <Row className="feedback-contact">
                  <Col xs={12}>
                    <p>
                      Our records indicate you have already provided feedback on your
                      JobAccelerator experience
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p>
                      If this is not correct, please contact our Candidate Care team on
                      1300 833 953 or via
                      <span> careertransition@hudson.com</span>
                      .
                    </p>
                  </Col>
                </Row>
              </Container>
            )}
            {isSubmitted && redirectURL && (
              <div className="feedback">
                <div className="feedback-group d-flex justify-content-center align-items-center">
                  <Button className="feedback-group-btn__view-dashboard" onClick={this.handleRedirectToPortal} disabled={isDisable}>View your Dashboard</Button>
                </div>
              </div>
            )}
          </>
        )}
        <ModalDefault
          open={isOpenCancelModal}
          handleClose={() => this.setState((prevState) => ({ ...prevState, isOpenCancelModal: false }))}
          data={dataCancelModal}
          handleChoice={() => this.handleCancelFeedback()}
        />
        <ToastContainer />
      </>
    );
  }
}

export default Feedback;
