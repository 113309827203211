/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

const getIcon = ({ name, regular, brand }) => {
  let icon = fas[name];
  if (regular) {
    icon = far[name];
  } else if (brand) {
    icon = fab[name];
  }
  if (!icon) {
    // default icon
    icon = fas.faBan;
  }
  return icon;
};

const renderIcon = ({
  id, name, regular, brand, color, size, className, style
}) => (
  <FontAwesomeIcon
    id={id || ''}
    icon={getIcon({ name, regular, brand })}
    color={color}
    size={size || '1x'}
    className={className || ''}
    style={style || ''}
  />
);

export default function Icon(props) {
  const { onClick, wrapperClass = '', stackIcons = [] } = props;

  if (stackIcons && Array.isArray(stackIcons) && stackIcons.length > 0) {
    const icons = [];
    stackIcons.map((icon) => {
      icons.push(<Fragment key={icon.name}>{renderIcon(icon)}</Fragment>);
      return true;
    });
    if (icons.length > 0) {
      return <span className="fa-layers fa-fw">{icons}</span>;
    }
  }

  return (
    <div className={`d-inline-flex align-items-center ${wrapperClass}`} onClick={onClick || null}>
      {renderIcon(props)}
    </div>
  );
}
