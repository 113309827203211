/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Rating from 'react-rating';
import './custom.scss';

function RatingPoint(props) {
  const {
    name,
    readOnly,
    initialRating,
    maxPoint = 5,
    emptySymbol,
    fullSymbol,
    onChange,
    stringRating,
    stringRatingMap,
    className = '',
    ratingTextClass = '',
  } = props;

  const [stringRatingHover, setStringRatingHover] = useState('');

  const onHover = (value) => {
    setStringRatingHover(stringRatingMap[value]);
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      <Rating
        className="rating-class"
        readonly={readOnly}
        stop={maxPoint}
        initialRating={initialRating}
        onChange={(value) => onChange(value, name)}
        onHover={(value) => onHover(value)}
        emptySymbol={emptySymbol}
        fullSymbol={fullSymbol}
      />
      {
        (stringRatingHover || stringRating) && (
          <span className={`rating-text ${ratingTextClass} ${initialRating === 0 && !stringRatingHover ? 'rating-text-none' : ''}`}>{stringRatingHover || stringRating}</span>
        )
      }
    </div>
  );
}
export default RatingPoint;
