/* eslint-disable react/prop-types */
import React from 'react';
import {
  Row,
  Col,
} from 'reactstrap';

function QuizComplete(props) {
  const {
    firstName,
    lastName,
    resultMessage,
  } = props;

  return (
    <Col xs={12}>
      <Row>
        <Col lg={{ size: 6, offset: 3 }} xs={12} md={{ size: 10, offset: 1 }} className="my-5 px-lg-0 px-5">
          <Row>
            <Col xs={12} md={10} lg={10} className="content-box">
              <p className="welcome-message">
                Welcome&nbsp;
                {firstName}
                &nbsp;
                {lastName}
              </p>
              <p className="complete-message font-weight-bold">{resultMessage || ''}</p>
            </Col>
            <div className="position-absolute circle">
              <svg className="d-lg-block d-none" width="394" height="394" viewBox="0 0 394 394" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse opacity="0.45" cx="210" cy="188" rx="184" ry="188" fill="#FFB940" />
                <ellipse opacity="0.45" cx="196" cy="189" rx="186" ry="187" fill="#FFB940" />
                <ellipse opacity="0.45" cx="184" cy="206" rx="184" ry="188" fill="#FFB940" />
                <ellipse cx="193" cy="189" rx="175" ry="177" fill="white" />
              </svg>
              <svg className="d-block d-lg-none" width="300" height="300" viewBox="0 0 394 394" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse opacity="0.45" cx="210" cy="188" rx="184" ry="188" fill="#FFB940" />
                <ellipse opacity="0.45" cx="196" cy="189" rx="186" ry="187" fill="#FFB940" />
                <ellipse opacity="0.45" cx="184" cy="206" rx="184" ry="188" fill="#FFB940" />
                <ellipse cx="193" cy="189" rx="175" ry="177" fill="white" />
              </svg>
            </div>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default QuizComplete;
