import React from 'react';
import './custom.scss';

function Loading() {
  return (
    <span className="loader" />
  );
}

export default Loading;
