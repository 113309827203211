const load = (writeKey, dataPlanUrl) => {
  try {
    window.rudderanalytics.load(writeKey, dataPlanUrl);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('rudderstack load', error);
  }
};

const track = (title, value) => {
  try {
    window.rudderanalytics.track(title, value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('rudderstack track', error);
  }
};

const pageView = (page) => {
  try {
    window.rudderanalytics.page(page);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('rudderstack pageView', error);
  }
};

const identify = (userId, data) => {
  try {
    window.rudderanalytics.identify(
      userId,
      data
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('rudderstack identify', error);
  }
};

const reset = () => {
  try {
    window.rudderanalytics.reset();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('rudderstack reset', error);
  }
};

export {
  track,
  pageView,
  identify,
  reset,
  load
};
