/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import './custom.scss';

export default function MultipleSelect(props) {
  const {
    listOptions,
    title,
    isClickable,
    listActiveValue = [],
    onChange,
    name
  } = props;
  const [inputVal, setInputVal] = useState(listActiveValue);
  useEffect(() => {
    setInputVal(listActiveValue);
  }, [listActiveValue]);

  const handleOnClick = (item) => {
    const val = [...inputVal];
    const pops = val.indexOf(item);
    if (pops > -1) {
      val.splice(pops, 1);
    } else {
      val.push(item);
    }
    setInputVal(val);
    return onChange(val, name);
  };

  return (
    <div key={`${title}-key`}>
      <div className="chip-group-component w-100">
        {title && (
          <div className="d-flex align-items-center my-4">
            <div className="ca-horizontal-line" />
            <div className="title mx-3 text-center">{title}</div>
            <div className="ca-horizontal-line" />
          </div>
        )}
        <div className="w-100 d-flex justify-content-center">
          <div className="chip-group-wrapper d-flex flex-wrap">
            {
              listOptions && listOptions.map((item) => (
                <div
                  key={item}
                  className={`chip-group-item ${isClickable && 'cursor-pointer'} ${!inputVal.includes(item) && 'in-active'}`}
                  onClick={() => (isClickable ? handleOnClick(item) : null)}
                  aria-hidden="true"
                >
                  {inputVal.includes(item) && <Icon size="1x" name="faCheck" />}
                  <div className="ml-2">{item}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}
