/* eslint-disable react/prop-types */
import React from 'react';
import DatePicker from 'react-datepicker';
import { Input } from 'reactstrap';

import Icon from '../Icon';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.scss';

function FloatingDatePicker(props) {
  const {
    selected, onChange, label, name = 'date-picker', minDate, maxDate
  } = props;
  const CustomInputDatePicker = React.forwardRef((propsDatePicker, ref) => (
    <div className="custom-input-date-picker" onClick={propsDatePicker.onClick} aria-hidden="true">
      <Input
        ref={ref}
        placeholder={propsDatePicker.placeholder}
        value={propsDatePicker.value}
        onChange={propsDatePicker.onChange}
        required
      />
      <span>{label}</span>
      <Icon name="faCalendarAlt" wrapperClass="custom-icon-date-picker" color="#828B93" />
    </div>
  ));
  return (
    <DatePicker
      customInput={<CustomInputDatePicker />}
      dateFormat="dd/MM/yyyy"
      selected={selected}
      onChange={(value) => onChange(value, name)}
      name={name}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}

export default FloatingDatePicker;
